export enum AssetsStorageKey {
	'companies' = 'companiesCache',
	'devices' = 'devicesCache',
	'deviceGroups' = 'deviceGroupsCache',
	'locations' = 'locationsCache',
	'sidebarExpanded' = 'sidebarExpanded',
	WEATHER_DEFAULT_DETAILS_VIEW = 'WEATHER_DEFAULT_DETAILS_VIEW',
	deviceSelectionPerUrl = 'deviceSelectionPerUrl',
}

export class AssetsStorageService {
	static get<T>(key: AssetsStorageKey, defaultValue: T | null = null): T | null {
		try {
			return JSON.parse(window.localStorage.getItem(key) || '');
		} catch {
			return defaultValue;
		}
	}

	static getMap<T>(key: AssetsStorageKey): Map<string, T> {
		try {
			const obj = this.get<{ [k: string]: T }>(key, {}) || {};
			return new Map<string, T>(Object.entries(obj));
		} catch {
			return new Map<string, T>();
		}
	}

	static setMap<T>(key: AssetsStorageKey, map: Map<string, T>) {
		const obj = Object.fromEntries(map);
		this.set(key, obj);
	}

	static set<T>(key: AssetsStorageKey, value: T) {
		window.localStorage.setItem(key, JSON.stringify(value));
	}

	static remove(key: AssetsStorageKey) {
		window.localStorage.removeItem(key);
	}

	static clear() {
		window.localStorage.clear();
	}
}

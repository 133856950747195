import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirOneSignalEnvironment extends AgdirFeatureEnvironment {
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirOneSignalEnvironment';
	readonly appId: string;
	readonly safari_web_id?: string;
	readonly notifyButton?: { enable: boolean };
	readonly serviceWorkerPath? = '/push/onesignal/OneSignalSDKWorker.js';
	readonly serviceWorkerUpdaterPath? = 'push/onesignal/OneSignalSDKWorker.js';
	readonly serviceWorkerParam? = {
		scope: '/push/onesignal/',
	};

	constructor(config: AgdirOneSignalEnvironment) {
		super(AgdirOneSignalEnvironment.NAME);
		this.appId = config.appId;
		this.safari_web_id = config.safari_web_id;
		this.notifyButton = config.notifyButton;
	}
}

import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirAwsEnvironment extends AgdirFeatureEnvironment {
	readonly region: string;
	readonly userPoolId: string;
	readonly userPoolWebClientId: string;
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirAwsEnvironment';

	constructor(config: AgdirAwsEnvironment) {
		super(AgdirAwsEnvironment.NAME);
		this.region = config.region;
		this.userPoolId = config.userPoolId;
		this.userPoolWebClientId = config.userPoolWebClientId;
	}
}

export enum ObservationName {
	NotSet = 0,
	Fertilizer = 3,
	Electrical = 5,
	SignalStrengthIndicator = 6,
	Conductivity = 7,
	Dielectricity = 8,
	Salinity = 9,
	FieldCapacity = 10,
	WiltingPoint = 11,
	WaterBalance = 12,
	BatteryStatus = 13,
	ConnectionMode = 14,
	RSSI = 16,
	DeviceStatus = 17,
	TransmissionMode = 18,
	SoilTemperature = 20,
	SoilMoisture = 21,
	AirTemperature = 22,
	AirHumidity = 23,
	AirPressure = 24,
	Touch = 25,
	MACAddress = 26,
	IPAddress = 27,
	BatteryInternal = 28,
	BatteryExternal = 29,
	SolarRadiation = 30, // GHI
	SolarPanel = 31,
	Precipitation = 32,
	PrecipitationProbability = 33,
	LeafWetness = 33,
	HCSerialNumber = 34,
	DewPoint = 35,
	VPD = 36,
	DeltaT = 37,
	WindSpeed = 38, //U-sonic wind speed
	WindDirection = 39, //U_SONIC_WIND_DIR
	WindSpeedGusts = 40,
	DailyET0 = 41,
	WindOrientation = 42,
	Midnight = 43,
	Sunrise = 44,
	Sunset = 45,
	SunshineDuration = 46,
	IconMetno = 47,
	IconMeteoBlue = 48,
	AirTemperatureMin = 49,
	AirTemperatureMax = 50,
	CloudAreaFraction = 51,
	CloudAreaFractionLow = 52,
	CloudAreaFractionMedium = 53,
	CloudAreaFractionHigh = 54,
	CloudCover = 55,
	FogAreaFraction = 56,
	AirTemperaturePercentile90 = 57,
	AirTemperaturePercentile10 = 58,
	WindSpeedPercentile90 = 59,
	WindSpeedPercentile10 = 60,
	PrecipitationMax = 61,
	PrecipitationMin = 62,
}

import { WellKnownClassifierId } from './wellKnownClassifierId';
import { ClassifierAttributeType } from './classifier-attribute-type';
import { WellKnownClassifierAttribute } from './well-known-classifier-attribute';

export interface ClassifierAttributeDto {
	type: ClassifierAttributeType;
	name: string | WellKnownClassifierAttribute;
	value: any;
	attributes?: ClassifierAttributeDto[];
	source?: 'agdir' | 'custom';
	allowedChildTypes?: ClassifierAttributeType[];
}

export class ClassifierAttribute {
	type = ClassifierAttributeType.String;
	name: string | WellKnownClassifierAttribute = '';
	value: any;
	attributes?: ClassifierAttribute[];
	source?: 'agdir' | 'custom';
	allowedChildTypes?: ClassifierAttributeType[];

	constructor(attr?: Partial<ClassifierAttribute>) {
		Object.assign(this, attr);
	}

	static fromDto(attr: ClassifierAttributeDto): ClassifierAttribute {
		const c = new ClassifierAttribute();
		if (WellKnownClassifierAttributes.has(attr.name as WellKnownClassifierAttribute)) {
			const wellKnown = WellKnownClassifierAttributes.get(attr.name as WellKnownClassifierAttribute)!;
			c.type = wellKnown.type;
			c.name = wellKnown.name;
			c.value = wellKnown.value;
			if (wellKnown.attributes) {
				c.attributes = wellKnown.attributes.map((a) => ClassifierAttribute.fromDto(a));
			} else {
				c.attributes = [];
			}
			c.source = wellKnown.source;
			c.allowedChildTypes = wellKnown.allowedChildTypes;
		}
		if ('type' in attr) c.type = attr.type;
		if ('name' in attr) c.name = attr.name;
		if ('value' in attr) c.value = attr.value;
		if ('attributes' in attr) c.attributes = (attr.attributes || []).map((a) => ClassifierAttribute.fromDto(a));
		if ('source' in attr) c.source = attr.source;
		if ('allowedChildTypes' in attr) c.allowedChildTypes = attr.allowedChildTypes;
		return c;
	}

	toDto(): ClassifierAttributeDto {
		return {
			type: this.type,
			name: this.name,
			value: String(this.value),
			attributes: (this.attributes || []).map((a) => a.toDto()),
			source: this.source,
			allowedChildTypes: this.allowedChildTypes,
		};
	}
}

export const WellKnownClassifierAttributes = new Map<WellKnownClassifierAttribute, ClassifierAttribute>([
	[
		WellKnownClassifierAttribute.affectedCrops,
		new ClassifierAttribute({
			name: WellKnownClassifierAttribute.affectedCrops,
			type: ClassifierAttributeType.Attributes,
			value: '',
			source: 'agdir',
			allowedChildTypes: [ClassifierAttributeType.Classifier],
			attributes: [new ClassifierAttribute({ name: '', type: ClassifierAttributeType.Classifier, value: '' })],
		}),
	],
	[
		WellKnownClassifierAttribute.reason,
		new ClassifierAttribute({
			name: WellKnownClassifierAttribute.reason,
			type: ClassifierAttributeType.Attributes,
			value: '',
			source: 'agdir',
			allowedChildTypes: [ClassifierAttributeType.Classifier],
			attributes: [new ClassifierAttribute({ name: '', type: ClassifierAttributeType.Classifier, value: '' })],
		}),
	],
	[
		WellKnownClassifierAttribute.pestAttribute,
		new ClassifierAttribute({
			name: WellKnownClassifierAttribute.pestAttribute,
			type: ClassifierAttributeType.Attributes,
			value: '',
			source: 'agdir',
			allowedChildTypes: [ClassifierAttributeType.Classifier],
			attributes: [new ClassifierAttribute({ name: '', type: ClassifierAttributeType.Classifier, value: '' })],
		}),
	],
]);

export const WellKnownClassifierAttributePresets = new Map<WellKnownClassifierId, ClassifierAttribute[]>([
	[WellKnownClassifierId.Pests, [WellKnownClassifierAttributes.get(WellKnownClassifierAttribute.affectedCrops)!]],
	[WellKnownClassifierId.CropProtectionWhy, [WellKnownClassifierAttributes.get(WellKnownClassifierAttribute.reason)!]],
	[WellKnownClassifierId.Pesticides, [WellKnownClassifierAttributes.get(WellKnownClassifierAttribute.pestAttribute)!]],
]);

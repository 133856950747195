type FeatureEnvironmentPrefix = `Agdir`;
type FeatureEnvironmentPostfix = `Environment`;
export type AgdirFeatureEnvironmentName = `${FeatureEnvironmentPrefix}${string}${FeatureEnvironmentPostfix}`;

export abstract class AgdirFeatureEnvironment {
	/**
	 * All envs may obfuscate to same letter :(
	 */
	protected constructor(public NAME?: AgdirFeatureEnvironmentName) {
		if (!NAME) {
			throw new Error('AgdirFeatureEnvironment constructor NAME is typed optional for simplier use, but actually its required');
		}
	}
}

/**
 * 1 - decomissioned TEMPERATURE as unprecise. Use SOIL_TEMPERATURE or AIR_TEMPERATURE
 * 2 - decomissioned HUMIDITY as unprecise. Use SOIL_MOISTURE or AIR_HUMIDITY
 * 4 - decomissioned PRESSURE as unprecise. Use AIR_PRESSURE
 * 15 - ignored as causing problems in TimeStream
 */
export enum MeasureNameForMongo {
	NOTSET = 0,
	FERTILIZER = 3,
	ELECTRICAL = 5,
	SIGNALSTRENGTHINDICATOR = 6,
	CONDUCTIVITY = 7,
	DIELECTRICITY = 8,
	SALINITY = 9,
	FIELDCAPACITY = 10,
	WILTINGPOINT = 11,
	WATERBALANCE = 12,
	BATTERYSTATUS = 13,
	CONNECTIONMODE = 14,
	RSSI = 16,
	DEVICE_STATUS = 17,
	TRANSMISSIONMODE = 18,
	SOIL_TEMPERATURE = 20,
	SOIL_MOISTURE = 21,
	AIR_TEMPERATURE = 22,
	AIR_HUMIDITY = 23,
	AIR_PRESSURE = 24,
	TOUCH = 25,
	MAC_ADDRESS = 26,
	IP_ADDRESS = 27,
	BATTERY_INTERNAL = 28,
	BATTERY_EXTERNAL = 29,
	SOLAR_RADIATION = 30, // GHI
	SolarPanel = 31,
	Precipitation = 32,
	LEAF_WETNESS = 33,
	HCSerialNumber = 34,
	DewPoint = 35,
	VPD = 36,
	DeltaT = 37,
	WIND_SPEED = 38, // U-sonic wind speed
	WIND_DIRECTION = 39, //U_SONIC_WIND_DIR
	WIND_GUST = 40,
	DAILY_ET0 = 41,
	WIND_ORIENTATION = 42,
	Midnight = 43,
	Sunrise = 44,
	Sunset = 45,
	SunshineDuration = 46,
}

export const AgdirMeasurementKindDict = new Map<MeasureNameForMongo, string>([
	[MeasureNameForMongo.NOTSET, 'unknown'],
	[MeasureNameForMongo.FERTILIZER, 'fertilizer'],
	[MeasureNameForMongo.ELECTRICAL, 'electrical'],
	[MeasureNameForMongo.SIGNALSTRENGTHINDICATOR, 'signal strength indicator'],
	[MeasureNameForMongo.CONDUCTIVITY, 'conductivity'],
	[MeasureNameForMongo.DIELECTRICITY, 'dielectricity'],
	[MeasureNameForMongo.SALINITY, 'salinity'],
	[MeasureNameForMongo.FIELDCAPACITY, 'field capacity'],
	[MeasureNameForMongo.WILTINGPOINT, 'wilting point'],
	[MeasureNameForMongo.WATERBALANCE, 'water balance'],
	[MeasureNameForMongo.SOIL_TEMPERATURE, 'water balance'],
	[MeasureNameForMongo.SOIL_MOISTURE, 'soil moisture'],
	[MeasureNameForMongo.AIR_TEMPERATURE, 'air temperature'],
	[MeasureNameForMongo.AIR_HUMIDITY, 'air humidity'],
	[MeasureNameForMongo.AIR_PRESSURE, 'air pressure'],
	[MeasureNameForMongo.BATTERY_EXTERNAL, 'battery'],
	[MeasureNameForMongo.BATTERY_INTERNAL, 'battery'],
	[MeasureNameForMongo.BATTERYSTATUS, 'BATTERYSTATUS'],
	[MeasureNameForMongo.CONNECTIONMODE, 'CONNECTIONMODE'],
	[MeasureNameForMongo.TOUCH, 'touched'],
	[MeasureNameForMongo.MAC_ADDRESS, 'mac address'],
	[MeasureNameForMongo.IP_ADDRESS, 'IP'],
	[MeasureNameForMongo.RSSI, 'RSSI'],
	[MeasureNameForMongo.DEVICE_STATUS, 'RSSI'],
	[MeasureNameForMongo.TRANSMISSIONMODE, 'TRANSMISSIONMODE'],
]);

import { deepReplace } from '@agdir/core/functions';
import { Company } from '@agdir/domain';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyService } from '../companies/company.service';
import { AgdirHttpClient, AgdirHttpV2Client } from './agdir-http-client.service';

export interface RestOptions {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	context?: HttpContext;
	observe?: 'body';
	params?:
		| HttpParams
		| {
				[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		  };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
	body?: any | null;
}

interface DecoratedUrlAndPayload<T> {
	url: string;
	payload?: T;
	restOptions?: RestOptions;
}

@Injectable({ providedIn: 'root' })
/**
 * Decorates calls with either companyId in the request payload or substitutes {companyId} in urls
 */
export class CompanyAssetsService {
	constructor(
		private httpClient: AgdirHttpClient,
		private companyService: CompanyService,
	) {}

	async getCompany(): Promise<Company> {
		return firstValueFrom(this.companyService.getCurrentCompany());
	}

	get<T>(url: string, restOptions?: RestOptions): Observable<T> {
		return this.decorateUrlAndPayload<T>(url, null, restOptions).pipe(
			switchMap((decorated) => this.httpClient.get<T>(decorated.url, decorated.restOptions)),
		);
	}

	put<T>(url: string, payload: any | null, restOptions?: RestOptions): Observable<T> {
		return this.decorateUrlAndPayload<T>(url, payload, restOptions).pipe(
			switchMap((decorated) => this.httpClient.put<T>(decorated.url, decorated.payload, decorated.restOptions)),
		);
	}

	delete<T>(url: string, payload?: any, restOptions?: RestOptions): Observable<T> {
		return this.decorateUrlAndPayload<T>(url, payload, restOptions).pipe(
			switchMap((decorated) =>
				this.httpClient.delete<T>(decorated.url, {
					...restOptions,
					body: decorated.payload,
				}),
			),
		);
	}

	post<T>(url: string, payload: any | null, restOptions?: RestOptions): Observable<T> {
		return this.decorateUrlAndPayload<T>(url, payload, restOptions).pipe(
			switchMap((decorated) => this.httpClient.post<T>(decorated.url, decorated.payload, decorated.restOptions)),
		);
	}

	patch<T>(url: string, payload: any | null, restOptions?: RestOptions): Observable<T> {
		return this.decorateUrlAndPayload<T>(url, payload, restOptions).pipe(
			switchMap((decorated) => this.httpClient.patch<T>(decorated.url, decorated.payload, decorated.restOptions)),
		);
	}

	private decorateUrlAndPayload<T>(url: string, payload: T | null = null, restOptions?: RestOptions): Observable<DecoratedUrlAndPayload<T>> {
		return this.companyService.getCurrentCompany().pipe(
			map((company) => {
				if (!company._id?.length) {
					return {
						url: url.replace('//', '/').replace('http:/', 'http://').replace('https:/', 'https://'),
						...(payload ? { payload: payload } : {}),
						...(restOptions ? { restOptions: restOptions } : {}),
					};
				}
				return {
					url: url
						.replace('{companyId}', company._id)
						.replace(encodeURI('{companyId}'), company._id)
						.replace('{countryOfOrigin}', company.countryOfOrigin || '')
						.replace(encodeURI('{countryOfOrigin}'), company.countryOfOrigin || '')
						.replace('//', '/')
						.replace('http:/', 'http://')
						.replace('https:/', 'https://'),
					...(payload ? { payload: { ...payload, companyId: company._id } } : {}),
					...(restOptions
						? {
								restOptions: { ...deepReplace<RestOptions>(restOptions, '{companyId}', company._id) },
								context: restOptions.context,
							}
						: {}),
				};
			}),
		);
	}

	getAsync<T>(url: string, restOptions?: RestOptions): Promise<T> {
		return firstValueFrom(this.get<T>(url, restOptions));
	}

	putAsync<T>(url: string, payload: any | null, restOptions?: RestOptions): Promise<T> {
		return firstValueFrom(this.put<T>(url, payload, restOptions));
	}

	deleteAsync<T>(url: string, payload?: any, restOptions?: RestOptions): Promise<T> {
		return firstValueFrom(this.delete<T>(url, payload, restOptions));
	}

	postAsync<T>(url: string, payload: any | null, restOptions?: RestOptions): Promise<T> {
		return firstValueFrom(this.post<T>(url, payload, restOptions));
	}

	patchAsync<T>(url: string, payload: any | null, restOptions?: RestOptions): Promise<T> {
		return firstValueFrom(this.patch<T>(url, payload, restOptions));
	}
}

@Injectable({ providedIn: 'root' })
export class CompanyAssetsV2Service extends CompanyAssetsService {
	constructor(httpClient: AgdirHttpV2Client, companyService: CompanyService) {
		super(httpClient, companyService);
	}
}

export enum CompanyEvents {
	AnyCompanyEvent = 'Company::',
	CreatedSoilScoutHub = 'Company::CreatedSoilScoutHub',
	CompanyCreated = 'Company::CompanyCreated',
	ChangedOrganizationName = 'Company::ChangedOrganizationName',
	NewCompanyRequested = 'Company::NewCompanyRequested',
	Changed = 'Company::Changed',
	CreatedD21sProject = 'Company::CreatedD21sProject',
	DetectedMissingSoilScoutSite = 'Company::DetectedMissingSoilScoutSite',
	DetectedMissingDTSite = 'Company::DetectedMissingDTSite',
}

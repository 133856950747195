// We got away with device type before. But Pessl has a configurable array of sensors which can differ from device to device.
// So these are not all of them. You will not find D21S humidy or temperature here. These are ones that are custom.

// Maybe in future we will make this for all devices.
export enum DeviceSensor {
	SOIL_TEMPERATURE = 'SOIL_TEMPERATURE',
	BATTERY = 'BATTERY',
	SOLAR_PANEL = 'SOLAR_PANEL',
	PRECIPITATION = 'PRECIPITATION',
	SOLAR_RADIATION = 'SOLAR_RADIATION',
	LEAF_WETNESS = 'LEAF_WETNESS',
	AIR_TEMPERATURE = 'AIR_TEMPERATURE',
	RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
	SUNSHINE_DURATION = 'SUNSHINE_DURATION',
	USONIC_WIND_DIR = 'U-SONIC_WIND_DIR',
	USONIC_WIND_SPEED = 'U-SONIC_WIND_SPEED',
	WIND_GUST = 'WIND_GUST',
	DELTAT = 'DELTAT',
	VPD = 'VPD',
	DEW_POINT = 'DEW_POINT',
	DAILY_ET0 = 'DAILY_ET0',
}

import { Feature, Point, Polygon } from '@turf/turf';

import { AccumulatedTemperatureSettings } from '../measurements';
import { UnderscoreIdModel } from '../underscore-id-model';
import { LocationAddress } from './location-address';
import { LocationStatus } from './location-status';
import { ProximityToWater } from './proximity-to-water';
import { Classifier } from '@agdir/classifiers';

export enum LocationType {
	field = 'field',
	fieldZone = 'fieldZone',
	building = 'building',
	turf = 'turf',
	weatherPlace = 'weatherPlace',
}

export type LocationWithId = Partial<Location> & Pick<Location, '_id'>;
export type ConcreteLocation = Partial<Location> & { _id: string; name: string };

export interface LocationForecastVendor {
	vendor: any;
	// vendor: ObservationVendor; // Circular dependency
	deviceId?: string;
	vendorSerialNumber?: string;
}

export type Location = UnderscoreIdModel & {
	companyId: string;
	parentId?: string;
	name: string;
	cropType?: string;
	cropClassifier?: Classifier; // added by FieldColorService until we migrate all crops to classifiers
	cropTypeVariant?: string;
	areaSizeM2?: number;
	areaSize?: number;
	areaSizeUnit?: string;
	createdAt?: string;
	sowedAt?: string;
	proximityToWater?: ProximityToWater;
	locationType?: LocationType;
	color?: string;
	linkedDeviceIds?: string[];
	status?: LocationStatus;
	geoJson?: Feature<Polygon>;
	accTempSettings?: AccumulatedTemperatureSettings;
	geoCenter?: Point;
	address?: LocationAddress;
	meta?: {
		isFavorite?: boolean;
		order?: number;
	};
	forecast?: LocationForecastVendor;
};

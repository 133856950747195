import { WeatherProperty } from '../deprecated-forecast/weather-property';
import { MeasureNameForMongo } from '@agdir/domain';

export const WeatherPropertyToMeasureNameMap = new Map<WeatherProperty, MeasureNameForMongo>([
	[WeatherProperty.air_temperature, MeasureNameForMongo.AIR_TEMPERATURE],
	[WeatherProperty.soiltemperature_0to10cm, MeasureNameForMongo.SOIL_TEMPERATURE],
	[WeatherProperty.precipitation_amount, MeasureNameForMongo.Precipitation],
	[WeatherProperty.daily_et0, MeasureNameForMongo['DAILY_ET0']],
	[WeatherProperty.relative_humidity, MeasureNameForMongo.AIR_HUMIDITY],
	[WeatherProperty.leaf_wetness, MeasureNameForMongo['LEAF_WETNESS']],
	[WeatherProperty.wind_speed, MeasureNameForMongo['WIND_SPEED']],
	[WeatherProperty.wind_orientation, MeasureNameForMongo.WIND_ORIENTATION],
	[WeatherProperty.wind_speed_of_gust, MeasureNameForMongo['WIND_GUST']],
	[WeatherProperty.global_radiation_ghi, MeasureNameForMongo['SOLAR_RADIATION']],
]);

export const deepReplace = <IN, F = string, R = string>(obj: IN, find: F, replace: R): IN => {
	if (typeof obj === 'object') {
		if (Array.isArray(obj)) {
			return obj.map((item) => deepReplace(item, find, replace)) as IN;
		} else {
			const result = {} as IN;
			for (const key in obj) {
				if (key in obj) {
					result[key] = deepReplace(obj[key], find, replace);
				}
			}
			return result as IN;
		}
	} else if (typeof obj === 'string') {
		return obj.replace(new RegExp(find as any, 'g'), replace as any) as IN;
	} else {
		return obj as IN;
	}
};

import { CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { CompanyAccessLevel, CompanyId } from '@agdir/domain';

export class Customer {
	_id = '';
	phone: string | undefined = '';
	email: string | undefined = '';
	name: string | undefined = '';
	surname: string | undefined = '';
	cognitoUser?: CognitoUser;
	companyPermissions: Record<CompanyId, CompanyAccessLevel> = {};

	constructor(cognitoUser?: CognitoUser | null) {
		if (cognitoUser) {
			this.cognitoUser = cognitoUser;
			this._id = this.cognitoUser.getUsername();
			this.cognitoUser.getUserAttributes((e?: Error, a?: CognitoUserAttribute[]) => {
				this.phone = a?.find((at) => at.Name === 'phone_number')?.getValue();
				this.email = a?.find((at) => at.Name === 'email')?.getValue();
				this.name = a?.find((at) => at.Name === 'given_name')?.getValue();
				this.surname = a?.find((at) => at.Name === 'family_name')?.getValue();
			});
		}
	}

	static initPool(params: ICognitoUserPoolData): CognitoUserPool {
		return new CognitoUserPool(params);
	}

	static async fromCurrentSession(params: ICognitoUserPoolData): Promise<Customer> {
		const u = Customer.initPool(params).getCurrentUser();
		u?.setAuthenticationFlowType('CUSTOM_AUTH');
		return new Customer(u).initSession();
	}

	setSession(session?: CognitoUserSession | null): Customer {
		const tokenPayload = session?.getIdToken().payload || {};
		this.companyPermissions = JSON.parse(tokenPayload['companies'] || '{}');
		this._id = tokenPayload['sub'];
		this.phone = tokenPayload['phone_number'];
		return this;
	}

	async initSession(): Promise<Customer> {
		return await new Promise((resolve) =>
			this.cognitoUser
				? this.cognitoUser?.getSession((e: Error, session: CognitoUserSession | null) => {
						if (e) {
							console.error(e);
						}
						this.setSession(session);
						resolve(this);
					})
				: resolve(this),
		);
	}
}

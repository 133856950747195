import { AgdirColors } from '@agdir/ui/colors';
import { Component, HostBinding, input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-skeleton-bar',
	template: '<ng-content></ng-content>',
	styles: [
		`
			:host {
				display: flex;
				border-radius: 3px;
				position: relative;
				overflow: hidden;
				justify-content: center;
				align-items: center;
			}

			/* The moving element */
			:host::after {
				display: block;
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				transform: translateX(-100%);
				/*background: -webkit-gradient(linear, left top,*/
				/*right top, from(transparent),*/
				/*color-stop(rgba(255, 255, 255, 0.2)),*/
				/*to(transparent));*/

				background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
				animation: loading 1s infinite;
			}

			/* Loading Animation */
			@keyframes loading {
				100% {
					transform: translateX(100%);
				}
			}
		`,
	],
})
export class SkeletonBarComponent {
	color = input<AgdirColors>(AgdirColors.gray);
	text = input<string | undefined>(undefined);
	@HostBinding('class')
	get hostClass() {
		return `bg-${this.color()}-300`;
	}
}

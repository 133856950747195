// export enum DeviceType {
// 	echo = 'echo',  // Soil Scout Echo repeater
// 	base = 'base',  // Soil Scout Base station
// 	hydra = 'hydra', // Soil Scout
// 	cloudconnector = 'ccon',
// 	humidity = 'humidity',
// }

// import {D21sDeviceType, SoilScoutDeviceType} from '../vendors';
//
// export const DeviceType = { ...D21sDeviceType, ...SoilScoutDeviceType };
// export type DeviceType = D21sDeviceType | SoilScoutDeviceType;

export enum DeviceType {
	// 'base' = SoilScoutDeviceType.base,
	'base' = 'base',
	// 'echo' = SoilScoutDeviceType.echo,
	'echo' = 'echo',
	// 'hydra' = SoilScoutDeviceType.hydra,
	'hydra' = 'hydra',
	// 'heat' = SoilScoutDeviceType.heat,
	'heat' = 'heat',
	// 'ccon' = D21sDeviceType.ccon,
	'ccon' = 'ccon',
	// 'humidity' = D21sDeviceType.humidity,
	'humidity' = 'humidity',
	'uMETOS CLIMA' = 'uMETOS CLIMA',
	'nMETOS80' = 'nMETOS80',
	'nMETOS100+' = 'nMETOS100+',
}

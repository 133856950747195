import { Company, Share } from '@agdir/domain';
import { Invitation } from '@agdir/heimdall';
import { CompanyService } from '@agdir/services';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AgdirHttpV2Client } from '../api';
import { toSignal } from '@angular/core/rxjs-interop';

const API = '/heimdall/share';

@Injectable({ providedIn: 'root' })
export class SharesService {
	currentCompany$ = inject(CompanyService).getCurrentCompany().pipe(shareReplay(1));
	companyCustomers$ = this.currentCompany$.pipe(
		switchMap((company) => this.getCompanyCustomers(company)),
		shareReplay(1),
	);

	companyCustomersSignal = toSignal(this.companyCustomers$);
	constructor(private httpClient: AgdirHttpV2Client) {}

	getCurrentCompanyCustomers() {
		return this.companyCustomers$.pipe(map((cs) => [...new Map(cs.map((customer) => [customer.customerId, customer])).values()]));
	}

	getCurrentCompanyCustomersSignal() {
		const companyCustomers = this.companyCustomersSignal() || [];
		return [...new Map(companyCustomers.map((customer) => [customer.customerId, customer])).values()];
	}

	getShareById(shareId: string): Observable<Share | null> {
		return this.getCurrentCompanyCustomers().pipe(map((shares) => shares.find((share) => share._id === shareId) ?? null));
	}

	getMyCompanies(skipCache = false): Observable<Share[]> {
		return this.httpClient.getWithCache<Share[]>(API, {}, { skipCache });
	}

	getMyCompaniesAsync(skipCache = false): Promise<Share[]> {
		return firstValueFrom(this.httpClient.getWithCache<Share[]>(API, {}, { skipCache }));
	}
	getCompanyCustomers(company: Company): Observable<Share[]> {
		return this.httpClient
			.get<Share[]>(`${API}/company/${company._id}`)
			.pipe(map((cs) => [...new Map(cs.map((customer) => [customer.customerId, customer])).values()]));
	}

	changePermission(newShare: Share): Observable<Share> {
		return this.currentCompany$.pipe(
			switchMap((company) =>
				this.httpClient.patch<Share>(`${API}/${newShare._id}`, {
					permission: newShare.permission,
				} as Share),
			),
		);
	}

	async revokeAccess(share: Share): Promise<void> {
		await this.httpClient.deleteAsync<unknown>(`${API}/${share._id}`);
		share.isSoftRevoked = true;
	}

	async createNewCompanyWithInvites(param: { invitations: Invitation[]; company: Company }) {
		return firstValueFrom(this.httpClient.post<void>(`${API}/company-with-customers`, param));
	}
}

import { SendMessageDataAction, WebsocketResponseMessage } from '@agdir/domain';
import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirApiEnvironment, Environment } from '@agdir/environment/domain';
import { Injectable, inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, EMPTY, ReplaySubject, catchError, firstValueFrom, from, switchMap } from 'rxjs';
import { AuthService } from '../customer';

@Injectable({
	providedIn: 'root',
})
export class WebsocketsService {
	public messages$ = new ReplaySubject<WebsocketResponseMessage<any>>(1);
	private authService = inject(AuthService);
	private environment: Environment = inject(ENVIRONMENT);

	private readonly connection = new HubConnectionBuilder()
		.withUrl(`${this.environment.get(AgdirApiEnvironment).hostv3}/wss`, {
			accessTokenFactory: async () => {
				return this.getJwtToken();
			},
		})
		.withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 5000 })
		.build();

	connection$ = new BehaviorSubject<HubConnection | undefined>(undefined);

	constructor() {
		this.connection.on('ReceiveMessage', (message: any) => {
			this.messages$.next(this.tryParseJSON(message));
		});
	}

	connect(companyId: string) {
		return from(
			new Promise(async (resolve, reject) => {
				try {
					await this.connection.stop();
					await this.connection.start();
					const token = await this.getJwtToken();
					const init = async () => {
						await this.connection.send(SendMessageDataAction.AuthSubscribe, companyId, token);
						this.connection$.next(this.connection);
					};
					this.connection.onreconnected(async () => {
						await init();
					});
					await init();

					resolve(this.connection);
				} catch (err) {
					reject(err);
				}
			}),
		).pipe(
			switchMap(() => this.messages$),
			catchError((err) => {
				console.error(err);
				return EMPTY;
			}),
		);
	}

	private async getJwtToken() {
		const customer = await firstValueFrom(this.authService.getCurrentCustomerSession());
		return customer.cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken()!;
	}

	private tryParseJSON(data: string): any {
		try {
			return JSON.parse(data);
		} catch {
			return data;
		}
	}
}

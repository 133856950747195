import { inject, Injectable } from '@angular/core';
import { AgdirHttpV2Client } from '@agdir/services';
import { firstValueFrom } from 'rxjs';
import { ExtendedTranslations } from '../extendedTranslations';
import { TranslationDotNotation } from '../translationDotNotation';
import { AgdirApiEnvironment } from '@agdir/core/angular';

@Injectable({
	providedIn: 'root',
})
export class I18nManagerService {
	http = inject(AgdirHttpV2Client);

	async updateKeyName(collection: string, language: string, key: string, value: string) {
		await firstValueFrom(this.http.patch(`/i18n/${collection}/${language}`, { key, value }));
	}

	async getTranslationVersion(
		collection: string,
		language: string,
		fromVersion = 0,
		toVersion = new Date().getTime(),
	): Promise<TranslationDotNotation[]> {
		return await firstValueFrom(
			this.http.get<TranslationDotNotation[]>(`/i18n/${collection}/${language}/version`, {
				params: {
					fromVersion,
					toVersion,
				},
			}),
		);
	}

	async convertTranslationToObject(collection: string, language: string, key: string, newState = {}) {
		await firstValueFrom(this.http.put(`/i18n/convert-to-object/${collection}/${language}/${key}`, newState));
	}

	async getExtendednTranslations(collection: any, language: any) {
		return await firstValueFrom(this.http.get<ExtendedTranslations>(`/i18n/extended/${collection}/${language}`));
	}

	async getVersions(
		collection: string,
		language: string,
	): Promise<
		Array<{
			version: number;
			previousVersion: number;
			date: Date;
			changes: number;
		}>
	> {
		const versions = await firstValueFrom(
			this.http.get<
				Array<{
					key: number;
					value: number;
				}>
			>(`/i18n/${collection}/${language}/versions/`),
		);
		return versions
			.map((v, index, all) => ({
				version: v.key,
				previousVersion: all[index - 1]?.key || 0,
				date: new Date(v.key * 1000),
				changes: v.value,
			}))
			.sort((a, b) => (a.date.getTime() > b.date.getTime() ? -1 : 1));
	}

	syncToProd(collection: string, language: string) {
		return this.http.put(`/i18n/${collection}/${language}/sync-to/${AgdirApiEnvironment.Prod}`, {});
	}
}

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthInterceptor } from './auth.interceptor';
import { HostPrefixInterceptor } from './host-prefix.interceptor';
import { JsonCircularDepFixInterceptor } from './json-circular-dep-fix.interceptor';
import { RestService } from './rest.service';

@NgModule({
	providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ApiModule {
	static forRoot(): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [
				{ provide: HTTP_INTERCEPTORS, useClass: JsonCircularDepFixInterceptor, multi: true },
				{ provide: HTTP_INTERCEPTORS, useClass: HostPrefixInterceptor, multi: true },
				{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
				RestService,
			],
		};
	}
}

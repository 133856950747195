import {
	defaultSoilScoutSoilType,
	DetectedDevice,
	Device,
	DeviceType,
	DeviceVendor,
	Location,
	MeasureForMongo,
	SoilScoutDefaults,
	TemperatureUnit,
} from '@agdir/domain';
import { inject, Injectable } from '@angular/core';
import { first, firstValueFrom, from, merge, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { CompanyAssetsService } from '../api/company-assets.service';
import { AssetsStorageKey, AssetsStorageService } from '../assets-storage.service';
import { CompanyService } from '../companies';
import { DeviceSoilscoutConnectorService } from './device-soilscout-connector.service';
import { ProfileService } from '../customer/profile.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root',
})
export class DevicesService {
	public readonly latestMeasures$ = new ReplaySubject<MeasureForMongo>(1);
	public readonly latestMeasuresSignal = toSignal(this.latestMeasures$, { initialValue: null });
	profileService = inject(ProfileService);
	private readonly localCache$ = new ReplaySubject<Device[]>(1);
	private readonly cache$: Observable<Device[]> = this.localCache$.asObservable().pipe(
		map((locations) =>
			[...(locations || [])].sort((a, b) => (String(a.name?.toLocaleLowerCase()) > String(b.name?.toLocaleLowerCase()) ? 1 : -1)),
		),
		shareReplay(1),
	);

	constructor(
		private restService: CompanyAssetsService,
		private deviceSoilscoutConnectorService: DeviceSoilscoutConnectorService,
		private companyService: CompanyService,
	) {
		this.companyService.whenChangingCompanies(() => AssetsStorageService.remove(AssetsStorageKey.devices));
	}

	devicesSignal = toSignal(this.cache$, { initialValue: [] });

	fetchDevices(): Observable<Device[]> {
		const temperatureUnit$ = from(this.profileService.getPreferenceAsync('temperatureUnit', TemperatureUnit.CELSIUS));
		const localCache = AssetsStorageService.get<Device[]>(AssetsStorageKey.devices);
		const remoteCall = temperatureUnit$.pipe(
			switchMap((temperatureUnit) =>
				this.restService.get<Device[]>(`/freya/device/{companyId}`, {
					params: { temperatureUnit: String(temperatureUnit) },
				}),
			),
			tap((devices) => AssetsStorageService.set(AssetsStorageKey.devices, devices)),
		);
		return localCache ? merge(of(localCache), remoteCall) : remoteCall;
	}

	putToCache(devices: Device[]): void {
		this.localCache$.next(devices);
	}

	reloadDeviceCache(): void {
		this.localCache$.pipe(first()).subscribe((cached) => this.localCache$.next(cached));
	}

	getAllDevices(): Observable<Device[]> {
		return this.cache$.pipe(map((devices) => [...devices]));
	}

	getDevicesByLocation(locationId: string, location?: Location): Observable<Device[]> {
		return this.cache$.pipe(
			map((devices) => [...devices.filter((e) => e.locationId == locationId || location?.linkedDeviceIds?.includes(String(e._id)))]),
		);
	}

	getLocationDevices(location: Location): Device[] {
		return this.devicesSignal().filter((e) => e.locationId == location._id || location?.linkedDeviceIds?.includes(String(e._id)));
	}

	guessVendor(serialNr: string): Observable<DetectedDevice> {
		return this.restService.get<DetectedDevice>(`/freya/guess-vendor-by-serial-number/${serialNr}`);
	}

	getAllDevicesMap(filterIds?: string[]): Observable<Map<string, Device>> {
		return this.cache$.pipe(
			map((devices) => new Map(devices.filter((d) => !filterIds || filterIds.includes(String(d._id))).map((d) => [String(d._id), d]))),
		);
	}

	getDevice(_id: string): Observable<Device> {
		return this.cache$.pipe(
			map((devices) => devices.find((d) => d._id === _id) as Device),
			// distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
		);
	}

	getDeviceAsync(_id: string): Promise<Device> {
		return firstValueFrom(this.getDevice(_id));
	}

	getAllDevicesAsync(): Promise<Device[]> {
		return firstValueFrom(this.getAllDevices());
	}

	syncFromDevice(device: Device): Observable<boolean> {
		return this.restService.post<boolean>(`/soilscout/sync-settings-from-hub`, device);
	}

	installDevice(device: Device): Observable<Device> {
		return this.prepareForInstall(device).pipe(
			switchMap(() => this.restService.post<Device>(`/freya/device`, device)),
			withLatestFrom(this.cache$),
			first(),
			map(([device, devices]) => {
				devices.push(device);
				this.localCache$.next([...devices]);
				return device;
			}),
		);
	}

	updateDevice(device: Partial<Device>): Observable<Device> {
		return this.restService.patch<Device>(`/freya/device`, device).pipe(
			switchMap(() => this.cache$),
			first(),
			map((devices) => {
				const d = devices.find((dev: Device) => dev._id === device._id) as Device;
				Object.assign(d, device);
				this.localCache$.next([...devices]);
				return d;
			}),
		);
	}

	deleteDevice(device: Device): Observable<unknown> {
		return this.restService.delete<unknown>(`/freya/device`, device).pipe(
			switchMap(() => this.cache$),
			first(),
			map((devices) => devices.filter((dev: Device) => dev._id !== device._id)),
			tap((updated) => this.localCache$.next([...updated])),
		);
	}

	private prepareForInstall(device: Device): Observable<Device> {
		device.installedAt = device.installedAt || new Date();
		return device.vendor === DeviceVendor.SoilScout1 ? this.prepareForSoilScoutInstall(device) : of(device);
	}

	private prepareForSoilScoutInstall(device: Device): Observable<Device> {
		if (device.type === DeviceType.hydra && device.vendor === DeviceVendor.SoilScout1) {
			device.soilConfiguration = device.soilConfiguration?.soilType
				? device.soilConfiguration
				: {
						...(device.soilConfiguration || SoilScoutDefaults.get(defaultSoilScoutSoilType)),
						soilType: device.soilConfiguration?.soilType || defaultSoilScoutSoilType,
					};
		}
		return this.companyService.getCurrentCompany().pipe(
			switchMap((company) => this.deviceSoilscoutConnectorService.waitForSoilScoutId(company)),
			map(() => device),
		);
	}
}

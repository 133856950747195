export enum ObservationWindow {
	instant,
	minute,
	hour,
	day,
	week,
	month,
	year,
	hour6,
}

import { I18nService } from '@agdir/i18n/angular';
import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { Classifier } from './classifier';
import { ClassifierService } from './classifier.service';
import { KnownClassifierCodes } from './knownClassifierCodes';

@Pipe({
	standalone: true,
	name: 'classifierCodeToName',
})
export class ClassifierPipe implements PipeTransform {
	private readonly classifier = inject(ClassifierService);
	private readonly i18nService = inject(I18nService);

	async transform(cropCode: string | null = null): Promise<string> {
		if (!cropCode) {
			return '';
		}
		const classifier = await this.classifier.getClassifierByCodeAsync(KnownClassifierCodes.CropTypes);
		const name = classifier?.findChildByCode(cropCode)?.name || '';
		return name.length ? this.i18nService.translate(name) : '';
	}
}

@Pipe({
	standalone: true,
	name: 'classifierIdToName',
})
export class ClassifierIdToNamePipe implements PipeTransform {
	private readonly classifier = inject(ClassifierService);
	private readonly i18nService = inject(I18nService);

	async transform(id: number | undefined | null): Promise<string> {
		if (!id) {
			return '';
		}
		const { name } = await this.classifier.getClassifierByIdAsync(id);
		return name || '';
	}
}

@Injectable()
@Pipe({
	standalone: true,
	name: 'classifierIdAttributeValue',
})
export class ClassifierIdAttributeValue implements PipeTransform {
	private readonly classifier = inject(ClassifierService);

	async transform(id: number | undefined, name: string, template = '{value}'): Promise<string> {
		if (!id) {
			return '';
		}
		const { attributes } = await this.classifier.getClassifierByIdAsync(id);
		const value = attributes?.find((a) => a.name == name)?.value || '';
		if (value) {
			return template.replace('{value}', value);
		}
		return '';
	}
}

@Injectable()
@Pipe({
	standalone: true,
	name: 'classifierAttributeValue',
})
export class ClassifierAttributeValue implements PipeTransform {
	private readonly classifier = inject(ClassifierService);

	transform(classifier: Classifier, name: string, template = '{value}'): string {
		const value = classifier.attributes?.find((a) => a.name == name)?.value || '';
		if (value) {
			return template.replace('{value}', value);
		}
		return '';
	}
}

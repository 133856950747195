import { computed, Injectable, signal } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';
import { AgdirHttpV2Client, WebsocketsService } from '@agdir/services';
import { InAppNotification, NewInAppNotification } from '@agdir/core/angular';
import { filter } from 'rxjs/operators';
import { tryParse } from '@agdir/core/functions';

@Injectable({
	providedIn: 'root',
})
export class InAppNotificationService {
	notifications = signal<InAppNotification[]>([]);
	hasNotifications = computed(() => this.notifications().filter((n) => !n.ack).length > 0);
	private apiUrl = '/notifications';

	constructor(
		private http: AgdirHttpV2Client,
		private websocketsService: WebsocketsService,
	) {
		timer(0, 600000).subscribe(() => this.fetchMessages(false));
		this.websocketsService.messages$
			.pipe(filter((msg) => msg.action === 'InAppNotification'))
			.subscribe((msg) => this.notifications.update((notifications) => [tryParse(msg.data), ...notifications]));
	}

	async storeNewMessage(notification: NewInAppNotification): Promise<void> {
		await firstValueFrom(this.http.post<void>(`${this.apiUrl}/new`, notification));
	}

	async acknowledgeMessage(notification: InAppNotification, processingSet?: Set<InAppNotification>): Promise<void> {
		if (processingSet) processingSet.add(notification);
		await firstValueFrom(this.http.put<void>(`${this.apiUrl}/acknowledge/${notification._id}`, {}));
		notification.ack = true;
		this.notifications.set(this.notifications());
		await this.fetchMessages();
		if (processingSet) processingSet.delete(notification);
	}

	async acknowledgeAllMessages(): Promise<void> {
		await firstValueFrom(this.http.put<void>(`${this.apiUrl}/acknowledgeall`, {}));
	}

	async fetchMessages(ack: boolean = false): Promise<void> {
		const notifications = await firstValueFrom(this.http.get<InAppNotification[]>(`${this.apiUrl}?ack=${ack}`));
		this.notifications.set(notifications.slice(0, 5));
	}
}

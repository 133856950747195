import { MeasureNameForMongo } from '@agdir/domain';
import { ObservationName } from '../observation-name';

export const mapObservationNameToMeasurementName = (o: ObservationName): MeasureNameForMongo => {
	return observationNameToMeasurementName.get(o) || MeasureNameForMongo.NOTSET;
};
export const observationNameToMeasurementName = new Map<ObservationName, MeasureNameForMongo>([
	[ObservationName.NotSet, MeasureNameForMongo.NOTSET],
	[ObservationName.Fertilizer, MeasureNameForMongo.FERTILIZER],
	[ObservationName.Electrical, MeasureNameForMongo.ELECTRICAL],
	[ObservationName.SignalStrengthIndicator, MeasureNameForMongo.SIGNALSTRENGTHINDICATOR],
	[ObservationName.Conductivity, MeasureNameForMongo.CONDUCTIVITY],
	[ObservationName.Dielectricity, MeasureNameForMongo.DIELECTRICITY],
	[ObservationName.Salinity, MeasureNameForMongo.SALINITY],
	[ObservationName.FieldCapacity, MeasureNameForMongo.FIELDCAPACITY],
	[ObservationName.WiltingPoint, MeasureNameForMongo.WILTINGPOINT],
	[ObservationName.WaterBalance, MeasureNameForMongo.WATERBALANCE],
	[ObservationName.BatteryStatus, MeasureNameForMongo.BATTERYSTATUS],
	[ObservationName.ConnectionMode, MeasureNameForMongo.CONNECTIONMODE],
	[ObservationName.RSSI, MeasureNameForMongo.RSSI],
	[ObservationName.DeviceStatus, MeasureNameForMongo.DEVICE_STATUS],
	[ObservationName.TransmissionMode, MeasureNameForMongo.TRANSMISSIONMODE],
	[ObservationName.SoilTemperature, MeasureNameForMongo.SOIL_TEMPERATURE],
	[ObservationName.SoilMoisture, MeasureNameForMongo.SOIL_MOISTURE],
	[ObservationName.AirTemperature, MeasureNameForMongo.AIR_TEMPERATURE],
	[ObservationName.AirHumidity, MeasureNameForMongo.AIR_HUMIDITY],
	[ObservationName.AirPressure, MeasureNameForMongo.AIR_PRESSURE],
	[ObservationName.Touch, MeasureNameForMongo.TOUCH],
	[ObservationName.MACAddress, MeasureNameForMongo.MAC_ADDRESS],
	[ObservationName.IPAddress, MeasureNameForMongo.IP_ADDRESS],
	[ObservationName.BatteryInternal, MeasureNameForMongo.BATTERY_INTERNAL],
	[ObservationName.BatteryExternal, MeasureNameForMongo.BATTERY_EXTERNAL],
	[ObservationName.SolarRadiation, MeasureNameForMongo.SOLAR_RADIATION],
	[ObservationName.SolarPanel, MeasureNameForMongo.SolarPanel],
	[ObservationName.Precipitation, MeasureNameForMongo.Precipitation],
	[ObservationName.LeafWetness, MeasureNameForMongo.LEAF_WETNESS],
	[ObservationName.HCSerialNumber, MeasureNameForMongo.HCSerialNumber],
	[ObservationName.DewPoint, MeasureNameForMongo.DewPoint],
	[ObservationName.VPD, MeasureNameForMongo.VPD],
	[ObservationName.DeltaT, MeasureNameForMongo.DeltaT],
	[ObservationName.WindSpeed, MeasureNameForMongo.WIND_SPEED],
	[ObservationName.WindDirection, MeasureNameForMongo.WIND_DIRECTION],
	[ObservationName.WindSpeedGusts, MeasureNameForMongo.WIND_GUST],
	[ObservationName.DailyET0, MeasureNameForMongo.DAILY_ET0],
	[ObservationName.WindOrientation, MeasureNameForMongo.WIND_ORIENTATION],
	[ObservationName.Midnight, MeasureNameForMongo.Midnight],
	[ObservationName.Sunrise, MeasureNameForMongo.Sunrise],
	[ObservationName.Sunset, MeasureNameForMongo.Sunset],
	[ObservationName.SunshineDuration, MeasureNameForMongo.SunshineDuration],
]);

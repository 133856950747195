import { ConcreteLocation, Device, DeviceVendor, Location, LocationEvents, LocationForecastVendor, LocationType } from '@agdir/domain';
import { ObservationVendor } from '@agdir/valknut/domain';
import { inject, Injectable } from '@angular/core';
import { area, Polygon } from '@turf/turf';
import { first, firstValueFrom, merge, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CompanyAssetsService } from '../api/company-assets.service';
import { AssetsStorageKey, AssetsStorageService } from '../assets-storage.service';
import { CompanyService } from '../companies';
import { DevicesService } from '../devices/devices.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { FieldColorService } from './field-color.service';

@Injectable({
	providedIn: 'root',
})
export class LocationsService {
	deviceService = inject(DevicesService);
	fieldColorService = inject(FieldColorService);
	private readonly localCache$ = new ReplaySubject<Location[]>(1);
	private readonly cache$: Observable<Location[]> = this.localCache$.pipe(
		map((locations) => locations.sort((a, b) => (a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase() ? 1 : -1))),
		shareReplay(1),
	);
	allLocations = toSignal(this.cache$);

	constructor(
		private companiesService: CompanyService,
		private restService: CompanyAssetsService,
	) {
		this.companiesService.whenChangingCompanies(() => AssetsStorageService.remove(AssetsStorageKey.locations));
	}

	putToCache(locations: Location[]): void {
		this.localCache$.next(locations);
	}

	fetchLocations<T extends Location>(): Observable<T[]> {
		const localCache = AssetsStorageService.get<T[]>(AssetsStorageKey.locations);
		const remoteCall = this.restService
			.get<T[]>(`/freya/location/{companyId}`)
			.pipe(tap((locations: T[]) => AssetsStorageService.set(AssetsStorageKey.locations, locations)));
		const r = localCache ? merge(of(localCache), remoteCall) : remoteCall;
		return r.pipe(switchMap((locations) => this.fieldColorService.adjustLocationCropColor<T>(locations)));
	}

	getLocationsAsync(): Promise<Location[]> {
		return firstValueFrom(this.getAllLocations<Location>());
	}
	getAllLocations<T = Location>(): Observable<T[]> {
		return this.cache$
			.pipe(map((locations) => [...locations]))
			.pipe(map((l) => l.sort((a, b) => (a.meta?.order || 0) - (b.meta?.order || 0)) as T[]));
	}

	getLocationsByTypes(types: LocationType[]) {
		return this.cache$.pipe(map((s) => s.filter((l) => types.includes(l.locationType!) || !l.locationType)));
	}

	getLocationsByType(type: LocationType) {
		return this.cache$.pipe(map((s) => s.filter((l) => l.locationType == type || !l.locationType)));
	}

	getLocationsByTypeAsync(type: LocationType): Promise<Location[]> {
		return firstValueFrom(this.getLocationsByType(type));
	}

	getConcreteLocationsByTypeAsync(type: LocationType): Promise<ConcreteLocation[]> {
		return this.getLocationsByTypeAsync(type) as Promise<ConcreteLocation[]>;
	}

	getConcreteLocationsByTypesAsync(types: LocationType[]): Promise<ConcreteLocation[]> {
		return firstValueFrom(this.getLocationsByTypes(types)) as Promise<ConcreteLocation[]>;
	}

	getAllLocationsSerialized(): Observable<Location[]> {
		const locations$ = this.cache$.pipe(map((locations) => [...locations]));
		return locations$.pipe(map((locations) => locations.map((location) => location)));
	}

	getLocation<T = Location>(_id: string): Observable<T> {
		return this.cache$.pipe(map((locations) => locations.find((loc) => loc._id === _id) as T));
	}

	getLocationAsync<T = Location>(_id: string): Promise<T> {
		return firstValueFrom(this.getLocation(_id));
	}

	getAreaByPolygon(polygon: Polygon): number {
		return Math.round(area(polygon));
	}

	createLocation(location: Partial<Location>): Observable<Location> {
		return this.companiesService.getCurrentCompany().pipe(
			first(),
			switchMap((company) =>
				this.restService.post<Location>(`/freya/location`, {
					...location,
					companyId: company._id,
				}),
			),
			withLatestFrom(this.cache$),
			tap(([{ _id: id }, locations]) => {
				locations.push({ ...location, _id: id } as Location);
				this.localCache$.next([...locations]);
			}),
			map(([location]) => location),
		);
	}

	async createLocationAsync(location: Partial<Location>): Promise<Location> {
		return firstValueFrom(this.createLocation(location));
	}

	deleteLocation(location: Location): Observable<unknown> {
		return this.restService.delete<unknown>(`/freya/location`, location).pipe(
			switchMap(() => this.cache$),
			first(),
			map((locations) => locations.filter((loc: Location) => loc._id !== location._id)),
			tap((updatedLocations) => this.localCache$.next([...updatedLocations])),
		);
	}

	saveLocation(location: Location, { updateCache } = { updateCache: true }): Observable<Location> {
		return this.restService
			.patch<Location>(`/freya/location`, {
				...location,
				_id: location._id,
			})
			.pipe(
				switchMap(() => this.cache$),
				first(),
				map((locations) => {
					if (updateCache) {
						const idx = locations.findIndex((loc: Location) => loc._id === location._id);
						locations[idx] = location;
						this.localCache$.next([...locations]);
					}
					return location;
				}),
			);
	}

	async tryToAddForecastDevice(location?: Location, device?: Device): Promise<void> {
		if (location && device?.vendor === DeviceVendor.Pessl) {
			await firstValueFrom(
				this.autoSaveLocation(
					location,
					'forecast',
					{
						vendor: ObservationVendor.Pessl,
						deviceId: String(device._id),
						vendorSerialNumber: device.serialNumber,
					},
					LocationEvents.ForecastVendorSet,
				),
			);
		}
	}

	async tryToRemoveForecastDevice(location?: Location, device?: Device): Promise<void> {
		if (location && location?.forecast?.deviceId === device?._id) {
			const [anotherPesslDevice] = await firstValueFrom(this.getLocationDevices(location, DeviceVendor.Pessl).pipe(first()));
			const nextForecastVendor = anotherPesslDevice
				? {
						vendor: ObservationVendor.Pessl,
						vendorId: device?._id,
						vendorSerialNumber: device?.serialNumber,
					}
				: { vendor: ObservationVendor.MetNo };
			await firstValueFrom(this.autoSaveLocation(location, 'forecast', nextForecastVendor, LocationEvents.ForecastVendorSet));
		}
	}

	autoSaveLocation<T extends keyof Location>(location: Location, property: T, value: Location[T], eventName: LocationEvents): Observable<Location> {
		Object.assign(location, { [property]: value });
		return this.restService
			.patch<Location>(
				`/freya/location`,
				{
					_id: location._id,
					[property]: value,
				},
				{ params: { eventName } },
			)
			.pipe(
				switchMap(() => this.cache$),
				first(),
				map((locations) => {
					const l = locations.find((loc: Location) => loc._id === location._id) as Location;
					Object.assign(l, { [property]: value });
					this.localCache$.next([...locations.filter((loc) => loc !== l), { ...l }]);
					return l;
				}),
			);
	}

	async setLocationForecastDeviceAsync(location: Location, device: Device | null): Promise<Location> {
		const vendorDevice: LocationForecastVendor = device
			? {
					vendor: ObservationVendor.Pessl,
					deviceId: String(device._id),
					vendorSerialNumber: device.serialNumber,
				}
			: { vendor: ObservationVendor.MetNo, deviceId: '' };
		return firstValueFrom(this.autoSaveLocation(location, 'forecast', vendorDevice, LocationEvents.ForecastVendorSet));
	}

	reload() {
		this.localCache$.pipe(first()).subscribe((locations) => this.localCache$.next([...locations]));
	}

	private getLocationDevices(location: Location, vendor?: DeviceVendor) {
		return this.deviceService
			.getDevicesByLocation(String(location._id))
			.pipe(map((devices) => devices.filter((device) => !vendor || device.vendor === vendor)));
	}
}

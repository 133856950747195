import { inject, Injectable } from '@angular/core';
import { Classifier, ClassifierService, WellKnownClassifierId } from '@agdir/classifiers';
import { CompanyCropPreference, Location, LocationType } from '@agdir/domain';
import { combineLatest, map, Observable } from 'rxjs';
import { CompanyService } from '../companies';

@Injectable({
	providedIn: 'root',
})
export class FieldColorService {
	private readonly classifierService = inject(ClassifierService);
	private readonly companyService = inject(CompanyService);

	adjustLocationCropColor<T extends Location>(locations: T[]): Observable<T[]> {
		return combineLatest([
			this.companyService.getCurrentCompany(),
			this.classifierService.getClassifierById(WellKnownClassifierId.CropTypes),
		]).pipe(
			map(([currentCompany, classifier]) =>
				locations.map((location) => {
					const cropClassifier = this.getCropTypeClassifier(classifier, location.cropType!);
					if (!cropClassifier) {
						return location;
					}
					if (location.color && location.locationType === LocationType.fieldZone) {
						return { ...location, color: location.color };
					}
					return {
						...location,
						cropClassifier,
						color: this.getCropColor(cropClassifier, currentCompany.preferences?.cropPreferences || [], classifier.children!),
					};
				}),
			),
		);
	}

	getCropColor(crop: Classifier, companyPreferences: CompanyCropPreference[], classifiers: Classifier[]) {
		if (!crop.parent) {
			return '#744f29';
		}
		const preferenceColor =
			companyPreferences.find((s) => s.cropId == crop.id)?.color || companyPreferences.find((s) => s.cropId == crop.parent?.id)?.color;
		if (preferenceColor) {
			return preferenceColor;
		}
		const cropColor = crop.getAttributeValue('color');
		if (cropColor) {
			return cropColor;
		}
		return crop.parent.getAttributeValue('color') || '#744f29';
	}

	getCropTypeClassifier(classifier: Classifier, code: string) {
		return classifier.findChildByCode(code);
	}
}

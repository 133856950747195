import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirMapboxEnvironment extends AgdirFeatureEnvironment {
	readonly accessToken: string;
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirMapboxEnvironment';

	constructor(config: AgdirMapboxEnvironment) {
		super(AgdirMapboxEnvironment.NAME);
		this.accessToken = config.accessToken;
	}
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject, switchMap } from 'rxjs';
import { RestOptions } from './company-assets.service';
import { USE_V3_API } from './index';

@Injectable({ providedIn: 'root' })
export class AgdirHttpClient {
	private cache = new Map<string, ReplaySubject<any>>([]);
	private httpClient = inject(HttpClient);

	protected get useV2Api(): boolean {
		return false;
	}

	getWithCache<T>(
		url: string,
		params?: RestOptions | null,
		hooks?: {
			skipCache?: boolean;
			onFirst?: (response: T) => void;
		},
	): Observable<T> {
		if (this.cache.has(url) && !hooks?.skipCache) {
			return this.cache.get(url)?.asObservable() as Observable<T>;
		} else {
			if (!this.cache.has(url)) {
				this.cache.set(url, new ReplaySubject<T>(1));
			}
			return this.get<T>(url).pipe(
				switchMap((response) => {
					if (hooks?.onFirst) {
						hooks?.onFirst(response);
					}
					const cached = this.cache.get(url);
					cached?.next(response);
					return cached?.asObservable() as Observable<T>;
				}),
			);
		}
	}

	get<T>(url: string, params?: RestOptions): Observable<T> {
		return this.httpClient.get<T>(url, this.constructParams(params));
	}

	put<T>(url: string, payload: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.put<T>(url, payload, this.constructParams(params));
	}

	post<T>(url: string, payload: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.post<T>(url, payload, this.constructParams(params));
	}

	patch<T>(url: string, payload: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.patch<T>(url, payload, this.constructParams(params));
	}

	delete<T>(url: string, params?: RestOptions): Observable<T> {
		return this.httpClient.delete<T>(url, this.constructParams(params));
	}

	getAsync<T>(url: string, params?: RestOptions): Promise<T> {
		return firstValueFrom(this.get<T>(url, this.constructParams(params)));
	}

	putAsync<T>(url: string, payload: any | null, params?: RestOptions): Promise<T> {
		return firstValueFrom(this.put<T>(url, payload, this.constructParams(params)));
	}

	postAsync<T>(url: string, payload: any | null, params?: RestOptions): Promise<T> {
		return firstValueFrom(this.post<T>(url, payload, this.constructParams(params)));
	}

	patchAsync<T>(url: string, payload: any | null, params?: RestOptions): Promise<T> {
		return firstValueFrom(this.patch<T>(url, payload, this.constructParams(params)));
	}

	deleteAsync<T>(url: string, params?: RestOptions): Promise<T> {
		return firstValueFrom(this.delete<T>(url, this.constructParams(params)));
	}

	private constructParams(params?: RestOptions) {
		return {
			...params,
			context: this.setupContext(params),
		};
	}

	private setupContext(params?: RestOptions) {
		if (params?.context) {
			params.context.set(USE_V3_API, this.useV2Api);
			return params.context;
		}
		return new HttpContext().set(USE_V3_API, this.useV2Api);
	}
}

@Injectable({ providedIn: 'root' })
export class AgdirHttpV2Client extends AgdirHttpClient {
	protected override get useV2Api(): boolean {
		return true;
	}
}

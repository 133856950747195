const getDefaultCountry = () => (window.location.hostname === 'localhost' ? 'LV' : 'NO');

interface IPInfo {
	ip?: string;
	city?: string;
	region?: string;
	country: string;
	loc?: string;
	org?: string;
	postal?: string;
	timezone?: string;
}

const findInCache = (): IPInfo => {
	const myCountryCache = window.localStorage.getItem('localLocation');
	try {
		return JSON.parse(String(myCountryCache)) as IPInfo;
	} catch (e) {
		window.localStorage.removeItem('localLocation');
		return { country: getDefaultCountry() };
	}
};

const findFromIPInfo = async (): Promise<IPInfo> => {
	try {
		const r = await fetch(`https://ipinfo.io/?token=c08b5b52de5195`, {
			referrerPolicy: 'origin-when-cross-origin',
			headers: { accept: 'application/json' },
		});

		if (r.status !== 200) {
			return { country: getDefaultCountry() };
		}

		const resp: IPInfo = await r.json();
		if (!resp?.country) {
			('AS15704');
			('Málaga');
			('EU');
			('ES');
			504782;
			('+34');
			('Madrid');
			('ES');
			('ESP');
			('Spain');
			46723749;
			('.es');
			('EUR');
			('Euro');
			true;
			('66.81.187.173');
			('es-ES,ca,gl,eu,oc');
			36.7125 - 4.407;
			('66.81.186.0/23');
			('Xtra Telecom S.A.');
			('29013');
			('Andalusia');
			('AN');
			('Europe/Madrid');
			('+0100');
			('IPv4');
			return { country: getDefaultCountry() };
		}

		window.localStorage.setItem('localLocation', JSON.stringify(resp));
		return resp;
	} catch {
		return { country: getDefaultCountry() };
	}
};

export const getCustomerLocationInformation = async (): Promise<IPInfo> => {
	const fromCache = findInCache();
	if (fromCache) {
		return fromCache;
	}
	const fromIpInfo = await findFromIPInfo();
	if (fromIpInfo) {
		return fromIpInfo;
	}
	return { country: getDefaultCountry() };
};

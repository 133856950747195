import { ClassifierSearch } from './classifierSearch';
import { Classifier } from './classifier';

/*
 * ClassifierList is a class that contains a list of classifiers.
 * Helps resolve issue when classifiers may contain rubbish
 */
export class ClassifierList {
	private readonly classifiers: Classifier[] = [];

	constructor(classifiers: Classifier[] = []) {
		this.classifiers = (classifiers.length ? classifiers : []).filter((c) => !!c);
	}

	findById(id: number): Classifier | null {
		return new ClassifierSearch(this).findChildById(id);
	}

	findByCode(code: string): Classifier | null {
		return new ClassifierSearch(this).findByCode(code);
	}

	add(classifier: Classifier): void {
		if (!classifier) return;
		this.classifiers.push(classifier);
	}

	toArray(): Classifier[] {
		return this.classifiers;
	}

	clone() {
		return new ClassifierList(this.classifiers.filter((c) => !!c).map((c) => c.clone()));
	}

	remove(classifier: Classifier) {
		const index = this.classifiers.findIndex((c) => c.id === classifier.id);
		if (index >= 0) {
			this.classifiers.splice(index, 1);
		}
	}
}

import { DeviceSoilConfiguration } from '../../devices/device';
import { SoilType } from '../../locations/soil-type';

export const defaultSoilScoutSoilType = SoilType.loam;

export const SoilScoutDefaults = new Map<SoilType, Partial<DeviceSoilConfiguration>>([
	[
		SoilType.clay,
		{
			soilDryBulkDensity: 1.1,
			fieldCapacity: 40,
			wiltingPoint: 20,
			irrigationThreshold: 0.5,
		},
	],
	[
		SoilType.sand,
		{
			soilDryBulkDensity: 1.6,
			fieldCapacity: 20,
			wiltingPoint: 5,
			irrigationThreshold: 0.5,
		},
	],
	[
		SoilType.organic,
		{
			soilDryBulkDensity: 0.5,
			fieldCapacity: 60,
			wiltingPoint: 5,
			irrigationThreshold: 0.5,
		},
	],
	[
		SoilType.loam,
		{
			soilDryBulkDensity: 1.4,
			fieldCapacity: 25,
			wiltingPoint: 8,
			irrigationThreshold: 0.5,
		},
	],
]);

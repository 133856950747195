import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../customer/auth.service';
import { IS_PUBLIC_API } from './index';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject = new BehaviorSubject<string | null>(null);

	constructor(
		private router: Router,
		private auth: AuthService,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.context.get(IS_PUBLIC_API) || req.method === 'JSONP' || req.url.startsWith('assets/') || req.url.startsWith('/assets/')) {
			return next.handle(req);
		}
		return this.auth.getCurrentCustomerSession().pipe(
			first(),
			switchMap((customer) => {
				const jwtToken = customer.cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken();
				const newReq = this.hydrateWithToken(req, jwtToken || '');
				// .set('ngsw-bypass', `true`)
				// .set('Cache-Control', `no-cache`)
				// .set('Pragma', `no-cache`),
				return next.handle(newReq).pipe(
					catchError((error) => {
						if (error instanceof HttpErrorResponse && error.status === 401) {
							return this.tryRefreshToken(req, next).pipe(
								catchError(() => {
									this.redirectToAuth();
									return EMPTY;
								}),
							);
						}
						return throwError(() => error);
					}),
				);
			}),
		);
	}

	private async redirectToAuth() {
		// this.auth.signOut();
		this.router.navigate(['auth', 'sign-in']);
	}

	private tryRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return from(this.auth.refreshToken()).pipe(
				switchMap((token) => {
					this.refreshTokenSubject.next(token);
					return next.handle(this.hydrateWithToken(request, token));
				}),
				finalize(() => {
					this.isRefreshing = false;
				}),
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((token) => {
					return next.handle(this.hydrateWithToken(request, token as string));
				}),
			);
		}
	}

	private hydrateWithToken(req: HttpRequest<any>, token: string | undefined) {
		return req.clone({
			headers: req.headers.set('Authorization', `Bearer ${token}`),
		});
	}
}

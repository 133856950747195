import { DistanceUnit } from '../units/distance';
import { TemperatureUnit } from '../units/temperatureUnit';
import { UnderscoreIdModel } from '../underscore-id-model';
import { SpeedUnit } from '../units/speed';

export const phoneNumber = 'phoneNumber';

export interface Profile extends UnderscoreIdModel {
	customerId: CustomerId;
	email?: string;
	phoneNumber?: string;
	name?: string;
	surname?: string;
	onboardingCompleted?: boolean;
	cropProtection?: Record<string, boolean>;
	cropProtectionCompliant?: boolean;
	consent?: boolean;
	marketing?: boolean;
	preferences?: CustomerPreferences;
	attributes?: Record<string, string>;
}

export interface CustomerPreferences {
	chartDefaultHours?: number;
	receivePushNotifications?: boolean;
	language?: string;
	temperatureUnit?: TemperatureUnit;
	distanceUnit?: DistanceUnit;
	speedUnit?: SpeedUnit;
}

export type CustomerId = string;

export type ProfileWithCustomerId = Partial<Profile> & Pick<Profile, 'customerId'>;

import { MeasureNameForMongo } from '@agdir/domain';
import { WeatherProperty } from '../deprecated-forecast/weather-property';

export const MeasureNameToWeatherPropertyMap = new Map<MeasureNameForMongo, WeatherProperty>([
	[MeasureNameForMongo.AIR_TEMPERATURE, WeatherProperty.air_temperature],
	[MeasureNameForMongo.SOIL_TEMPERATURE, WeatherProperty.soiltemperature_0to10cm],
	[MeasureNameForMongo.Precipitation, WeatherProperty.precipitation_amount],
	[MeasureNameForMongo['DAILY_ET0'], WeatherProperty.daily_et0],
	[MeasureNameForMongo.AIR_HUMIDITY, WeatherProperty.relative_humidity],
	[MeasureNameForMongo['LEAF_WETNESS'], WeatherProperty.leaf_wetness],
	[MeasureNameForMongo['WIND_SPEED'], WeatherProperty.wind_speed],
	[MeasureNameForMongo.WIND_ORIENTATION, WeatherProperty.wind_orientation],
	[MeasureNameForMongo['WIND_GUST'], WeatherProperty.wind_speed_of_gust],
	[MeasureNameForMongo['SOLAR_RADIATION'], WeatherProperty.global_radiation_ghi],
]);

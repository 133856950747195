export { TimeAgoPipe } from './lib/time-ago.pipe';
export { ValueWidgetComponent } from './lib/agdir-value-widget.component';

export { AgdirCardComponent } from './lib/agdir-card.component';
export { DashboardWidgetComponent } from './lib/dashboard-widget.component';

export { BaseComponent } from './lib/base.component';
export { BaseDirective } from './lib/base.directive';
export { getCustomerLocationInformation } from './lib/detect-customer-country';
export { LocalStore } from './lib/local.store';
export { clearBrowserCache } from './lib/util';
export * from './lib/in-app-notification.model';
export * from './lib/item-name.pipe';
export * from './lib/responsive';

export enum AgdirApiEnvironment {
	Prod,
	Stg,
}

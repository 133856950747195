import { AgdirFeature, AgdirFeatures, FeatureEvent } from '@agdir/features/domain';
import { CompanyAssetsService, WebsocketsService } from '@agdir/services';
import { inject, Injectable } from '@angular/core';
import { filter, map, merge, Observable, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FeatureToggleService {
	private readonly httpClient = inject(CompanyAssetsService);
	private readonly ws = inject(WebsocketsService);
	private readonly cache$ = merge(of(true), this.ws.messages$.pipe(filter((m) => m.action === FeatureEvent.FeatureChanged))).pipe(
		switchMap(() => this.httpClient.get<AgdirFeatures>('/features/access', { params: { companyId: '{companyId}' } })),
		shareReplay(1),
	);

	getAllFeatures(): Observable<AgdirFeatures> {
		return this.cache$;
	}

	getFeature(agdirFeatureToggle: AgdirFeature): Observable<boolean> {
		return this.getAllFeatures().pipe(map((features) => features[agdirFeatureToggle]));
	}
}

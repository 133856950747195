export type AgdirColor = 'jord' | 'bringbær' | 'sol' | 'strå' | 'spire' | 'vann' | 'svartvann' | 'luft';

export enum AgdirColors {
	brown = 'jord', // brown
	red = 'bringbær', // red
	orange = 'sol', // orange
	yellow = 'strå', // yellow
	green = 'spire', // green
	blue = 'vann', // blue
	darkBlue = 'svartvann', // dark blue
	lightBlue = 'luft', // light blue
	gray = 'gray', // gray
}

export const DefaultAgdirColor = AgdirColors.lightBlue;

export enum SpeedUnit {
	MetersPerSecond = 1,
	MilesPerHour = 2,
}

export const SpeedUnitToSymbol = (unit: SpeedUnit): string => {
	switch (unit) {
		case SpeedUnit.MetersPerSecond:
			return 'm/s';
		case SpeedUnit.MilesPerHour:
			return 'mph';
		default:
			return '';
	}
};

const convertors = new Map<SpeedUnit, Map<SpeedUnit, (value: number) => number>>([
	[
		SpeedUnit.MetersPerSecond,
		new Map([
			[SpeedUnit.MilesPerHour, (mps: number): number => mps * 2.23694],
			[SpeedUnit.MetersPerSecond, (value) => value],
		]),
	],
	[
		SpeedUnit.MilesPerHour,
		new Map([
			[SpeedUnit.MetersPerSecond, (mph: number): number => mph * 0.44704],
			[SpeedUnit.MilesPerHour, (value) => value],
		]),
	],
]);

export const convertSpeedUnit = (value: number | string | undefined | null, fromUnit: SpeedUnit, toUnit: SpeedUnit): number | null => {
	if (isNaN(Number(value)) || value === null || value === undefined) {
		return null;
	}
	return convertors.get(+fromUnit)?.get(+toUnit)?.(+value) ?? +value;
};

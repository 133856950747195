export enum VolumeUnit {
	LITER = 1,
	GALLON = 2,
}

export const VolumeUnitToSymbol = (unit: VolumeUnit | null = null): string => {
	switch (unit) {
		case VolumeUnit.LITER:
			return 'L';
		case VolumeUnit.GALLON:
			return 'gal';
		default:
			return '';
	}
};

const convertors = new Map<VolumeUnit, Map<VolumeUnit, (value: number) => number>>([
	[
		VolumeUnit.LITER,
		new Map([
			[VolumeUnit.GALLON, (liters: number): number => liters / 3.78541],
			[VolumeUnit.LITER, (value) => value],
		]),
	],
	[
		VolumeUnit.GALLON,
		new Map([
			[VolumeUnit.LITER, (gallons: number): number => gallons * 3.78541],
			[VolumeUnit.GALLON, (value) => value],
		]),
	],
]);

export const convertVolumeUnit = (value: number | string | undefined | null, fromUnit: VolumeUnit, toUnit: VolumeUnit): number | null => {
	if (isNaN(Number(value)) || value === null || value === undefined) {
		return null;
	}
	return convertors.get(+fromUnit)?.get(+toUnit)?.(+value) ?? +value;
};

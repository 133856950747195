import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirApiEnvironment, Environment } from '@agdir/environment/domain';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USE_V3_API } from './index';

const LOCAL_PROXY_REWRITES = ['http://localhost:3000'];
const EXTERNAL_SECURE_SITE = 'https://';
const EXTERNAL_SAME_PROTOCOL_SITE = '//';
const ASSETS = 'assets/';

@Injectable()
export class HostPrefixInterceptor implements HttpInterceptor {
	constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req.clone({ url: this.cleanUrl(this.makeRoute(req)) }));
	}

	private makeRoute(req: HttpRequest<any>) {
		const route = req.url;

		if (route.startsWith(EXTERNAL_SECURE_SITE)) {
			return route;
		}

		if (route.startsWith(EXTERNAL_SAME_PROTOCOL_SITE)) {
			return route;
		}

		if (route.startsWith(ASSETS) || route.startsWith(ASSETS, 1)) {
			return route;
		}

		if (LOCAL_PROXY_REWRITES.some((s) => route.startsWith(s))) {
			return route;
		}
		return `${this.getRestRoot(req)}/${route}`;
	}

	private getRestRoot(req: HttpRequest<any>): string {
		const { host, hostv3 } = this.environment.get(AgdirApiEnvironment);
		if (req.context.get(USE_V3_API)) {
			return hostv3;
		}
		return host;
	}

	private cleanUrl(url: string): string {
		return url.replace(/([^:]\/)\/+/g, '$1');
	}
}

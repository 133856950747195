import { isString } from '@agdir/core/functions';
import { addMinutes, format as formatDate, startOfDay, startOfHour, startOfMinute, startOfMonth, startOfYear, subMinutes } from 'date-fns';
import { Observation } from '../observation';
import { ObservationName } from '../observation-name';
import { ObservationWindow } from '../observation-window';

function findMiddleOfRangeWhichIsProbablyNoon(numbers: number[]): number {
	const middle = Math.floor(numbers.length / 2);
	return numbers[middle];
}

function findMostFrequentNumber(numbers: number[]): number {
	const frequencyMap: Map<number, number> = new Map();

	for (const number of numbers) {
		if (frequencyMap.has(number)) {
			frequencyMap.set(number, frequencyMap.get(number)! + 1);
		} else {
			frequencyMap.set(number, 1);
		}
	}

	let mostFrequentNumber = numbers[0];
	let highestFrequency = 1;

	for (const [number, frequency] of Array.from(frequencyMap.entries()) as [number, number][]) {
		if (frequency > highestFrequency) {
			mostFrequentNumber = number;
			highestFrequency = frequency;
		}
	}

	return mostFrequentNumber;
}

const avgMeasures = (measures: Observation[]) => measures.reduce((acc, cur) => acc + cur.value || 0, 0) / measures.length;
const sumMeasures = (measures: Observation[]) => measures.reduce((acc, cur) => acc + cur.value || 0, 0);

const functions = new Map<ObservationName | 'default', (measures: Observation[]) => number>([
	['default', avgMeasures],
	[ObservationName.Precipitation, sumMeasures],
	[ObservationName.LeafWetness, sumMeasures],
	[ObservationName.PrecipitationMin, sumMeasures],
	[ObservationName.PrecipitationMax, sumMeasures],
	[ObservationName.IconMetno, (measures) => findMostFrequentNumber(measures.map((m) => +m.value))],
	[ObservationName.IconMetno, (measures) => findMiddleOfRangeWhichIsProbablyNoon(measures.map((m) => +m.value))],
	[ObservationName.IconMeteoBlue, (measures) => findMiddleOfRangeWhichIsProbablyNoon(measures.map((m) => +m.value))],
]);

export class ObservationGrouper {
	static formatInTz(time: Date | string, tzOffset: number, format: string): string {
		const date = isString(time) ? new Date(time) : time;
		return formatDate(addMinutes(date, tzOffset), format);
	}

	static formatDateByObservationWindow(date: Date | string, window: ObservationWindow, tzOffset: number) {
		switch (+window) {
			case ObservationWindow.year:
				return ObservationGrouper.formatInTz(date, tzOffset, 'yyyy');
			case ObservationWindow.month:
				return ObservationGrouper.formatInTz(date, tzOffset, 'yyyy-MM');
			case ObservationWindow.day:
				return ObservationGrouper.formatInTz(date, tzOffset, 'yyyy-MM-dd');
			case ObservationWindow.hour:
				return ObservationGrouper.formatInTz(date, tzOffset, `yyyy-MM-dd'T'HH`);
			case ObservationWindow.minute:
				return ObservationGrouper.formatInTz(date, tzOffset, `yyyy-MM-dd'T'HH:mm`);
			default:
				return ObservationGrouper.formatInTz(date, tzOffset, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);
		}
	}

	static calculateDateByObservationWindow(time: Date | string, window: ObservationWindow, tzOffset: number = 60): Date {
		const date = isString(time) ? new Date(time) : time;
		const zonedDate = addMinutes(date, tzOffset);

		switch (+window) {
			case ObservationWindow.year:
				return startOfYear(zonedDate);
			case ObservationWindow.month:
				return startOfMonth(zonedDate);
			case ObservationWindow.day:
				return startOfDay(zonedDate);
			case ObservationWindow.hour:
				return startOfHour(zonedDate);
			case ObservationWindow.minute:
				return startOfMinute(zonedDate);
			default:
				return zonedDate;
		}
	}

	static calculateGroupValue(measures: Array<Observation>) {
		const [first] = measures;
		const fn = functions.get(first.name) || functions.get('default');
		return fn(measures);
	}

	static getGroupKey(measurement: Observation, groupBy: ObservationWindow, tzOffset: number): string {
		const k: string[] = [];
		if ('device' in measurement) {
			k.push(String(measurement.device?._id));
		} else if ('vendor' in measurement) {
			k.push(String(measurement.vendor));
		}
		k.push(String(measurement.name));
		k.push(ObservationGrouper.formatDateByObservationWindow(measurement.time, groupBy, tzOffset));
		return k.join(':');
	}

	static group(observations: Observation[], groupBy: ObservationWindow, tzOffset: number = 0): Observation[] {
		if (groupBy === ObservationWindow.instant) {
			return observations;
		}

		const groups = new Map<string, Observation[]>([]);

		observations.forEach((measurement) => {
			const k = this.getGroupKey(measurement, groupBy, tzOffset);
			if (!groups.has(k)) {
				groups.set(k, []);
			}
			groups.get(k)?.push(measurement);
		});

		return [...groups.values()]
			.map((measures) => {
				return {
					...measures[0],
					time: ObservationGrouper.calculateDateByObservationWindow(measures[0].time, groupBy, tzOffset),
					value: this.calculateGroupValue(measures),
					unit: measures[0].unit,
					max: Math.max(...measures.map((m) => m.value || 0)),
					min: Math.min(...measures.map((m) => m.value || 0)),
				};
			})
			.map((o) => ({
				...o,
				time: tzOffset ? subMinutes(new Date(o.time), tzOffset) : o.time,
			}));
	}
}

import { AgdirDeviceAvailability } from './agdir-device-availability';
import { ConfirmedByVendor } from './confirmed-by-vendor';
import { Device } from './device';
import { DeviceType } from './device-type';
import { DeviceVendor } from './device-vendor';

export class DetectedDevice {
	queueStatus?: 'queued' | 'pending' | 'done' | 'error' = 'queued';
	confirmedByVendor = ConfirmedByVendor.UNDETECTED;
	availability = AgdirDeviceAvailability.notInInventory;
	device: Partial<Device> & Pick<Device, '_id' | 'serialNumber' | 'vendor' | 'externalIdentity' | 'pollingConfiguration'>;
	originalSerialNumber: string;

	constructor(typedSerialNumber?: string, vendor?: DeviceVendor, type?: DeviceType) {
		this.originalSerialNumber = typedSerialNumber?.trim() ?? '';
		const serialNumber = this.originalSerialNumber.replace(/(BST|ECHO)?( |_)?/g, '');
		this.device = {
			_id: null,
			serialNumber,
			vendor: vendor || DeviceVendor.NotSet,
			type: type,
			externalIdentity: '',
		} as Device;
		this.setupVendor(serialNumber, vendor, type);
	}

	static make(config: Partial<DetectedDevice> & Pick<DetectedDevice, 'device'>): DetectedDevice {
		const d = new DetectedDevice(config.device.serialNumber);
		d.device.type = config.device.type;
		d.device.pollingConfiguration = config.device.pollingConfiguration;
		if (config.confirmedByVendor) {
			d.confirmedByVendor = config.confirmedByVendor;
		}
		if (config.availability) {
			d.availability = config.availability;
		}
		if (config.queueStatus) {
			d.queueStatus = config.queueStatus;
		}
		return d;
	}

	hydrateWithDevice(device?: Device): void {
		if (device) {
			this.device = device;
			this.confirmedByVendor = ConfirmedByVendor.CONFIRMED;
		}
	}

	atLeastRecognized(): boolean {
		return (this.confirmedByVendor & ConfirmedByVendor.RECOGNIZED) === ConfirmedByVendor.RECOGNIZED;
	}

	setupVendor(serialNumber: string, deviceVendor?: DeviceVendor, type?: DeviceType) {
		const vendor = deviceVendor || this.tryToGuessVendor(serialNumber);

		this.device.vendor = vendor;
		this.device.type = type;

		if (vendor === DeviceVendor.VardeV1) {
			this.confirmedByVendor = ConfirmedByVendor.RECOGNIZED;
			this.device.externalIdentity = this.device.serialNumber;
		} else if (vendor === DeviceVendor.DisruptiveTech1) {
			this.confirmedByVendor = ConfirmedByVendor.RECOGNIZED;
			this.device.externalIdentity = this.device.serialNumber;
		} else if (vendor === DeviceVendor.SoilScout1) {
			this.confirmedByVendor = ConfirmedByVendor.RECOGNIZED;
		} else if (vendor === DeviceVendor.Pessl) {
			this.confirmedByVendor = ConfirmedByVendor.RECOGNIZED;
		}
	}

	private tryToGuessVendor(sn?: string): DeviceVendor {
		const serialNumber = sn ?? this.device.serialNumber ?? '';
		const looksLikeFriland = /^\d{15}$/gm.test(serialNumber);
		const looksLikeDT = /^(emu)?[e|c|b][\w]{19}$/gm.test(serialNumber);
		const looksLikeSS = /^(BST|ECHO)?[ _]?\d{3,5}$/gm.test(serialNumber);
		const looksLikePessl = /^\w{8}$/gm.test(serialNumber);
		// const looksLikePessl = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]+$/gm.test(serialNumber);

		if (looksLikeFriland) {
			return DeviceVendor.VardeV1;
		} else if (looksLikeDT) {
			return DeviceVendor.DisruptiveTech1;
		} else if (looksLikeSS) {
			return DeviceVendor.SoilScout1;
		} else if (looksLikePessl) {
			return DeviceVendor.Pessl;
		}
		return DeviceVendor.NotSet;
	}
}

export enum MeasureUnitForMongo {
	NOTSET = 0,
	VOLUME_MILLILITER = 1,
	VOLUME_CENTILITER = 2,
	VOLUME_DESILITER = 3,
	VOLUME_LITER = 4,
	VOLUME_DEKALITER = 5,
	VOLUME_HEKTOLITER = 6,
	VOLUME_KILOLITER = 7,
	WEIGTH_MILLIGRAM = 8,
	WEIGTH_GRAM = 9,
	WEIGTH_HEKTOGRAM = 10,
	WEIGTH_KILOGRAM = 11,
	WEIGTH_TONN = 12,
	LENGTH_MILLIMETER = 13,
	LENGTH_DESIMETER = 14,
	LENGTH_METER = 15,
	LENGTH_KILOMETER = 16,
	AREA_SQUAREMETER = 17,
	AREA_DEKAR = 18,
	AREA_HEKTAR = 19,
	AREA_SQUAREKILOMETER = 20,
	TEMPERATURE_CELCIUS = 21,
	TEMPERATURE_FAHRENHEIT = 22,
	TEMPERATURE_KELVIN = 23,
	TIME_MILLISECOND = 24,
	TIME_SECOND = 25,
	TIME_MINUTE = 26,
	TIME_HOUR = 27,
	TIME_DAY = 28,
	PRESSURE_PA = 29,
	PRESSURE_KPA = 30,
	PRESSURE_BAR = 31,
	PRESSURE_KBAR = 32,
	PRESSURE_HPA = 33,
	FRACTION = 34,
	PERCENT = 35,
	ELECTRICAL_VOLT = 36,
	ELECTRICAL_MILLIVOLT = 37,
	SIGNALSTRENGTHINDICATOR_LEVELOF0TO31 = 38,
	CASUAL_SACK = 39,
	CASUAL_BASKET = 40,
	CASUAL_CASESMALL = 41,
	CASUAL_CASEBIG = 42,
	ELECTRICAL_CONDUCTIVITY_DESISIEMENSPERMETER = 43,
	CONNECTIONMODE_DISRUPTIVE1 = 44,
	RSSI_DISRUPTIVE1 = 45,
	DEVICE_STATUS_SOILSCOUT1 = 46,
	TRANSMISSIONMODE_DISRUPTIVE1 = 47,
	RSSI_SOILSCOUT1 = 48,
	DBM = 49,
	'W/m2',
	'J/m2',
	'kJ/m2',
	'MJ/m2',
	'in',
	'ft',
	'yd',
	'm (from hPa)',
	'in (from hPa)',
	'mbar',
	'cbar',
	'mmHg',
	'psi',
	'm/s',
	'km/h',
	'mph',
	'ft/min',
	'ft/s',
	'yd/min',
	'kn',
	'deg',
}

export enum AreaUnit {
	SQUAREMETER = 'm²',
	DEKAR = 'daa',
	HEKTAR = 'ha',
	SQUAREKILOMETER = 'km²',
	ACRE = 'acre',
}

export enum IrrigationTimeUnit {
	MINUTE = 'minutes',
	HOUR = 'hours',
	DAY = 'days',
	WEEK = 'weeks',
}

import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class Environment {
	readonly env: string;
	readonly production: boolean;
	readonly remotes = new Map<'farmSiteUrl', string>([['farmSiteUrl', 'https://agdir.farm']]);
	private readonly features = new Map<AgdirFeatureEnvironmentName, AgdirFeatureEnvironment>();

	constructor(config: { env: string; production: boolean; remotes?: Map<'farmSiteUrl', string> }) {
		this.env = config.env;
		this.production = config.production;
		if (config.remotes) {
			this.remotes = config.remotes;
		}
	}

	get isLocal() {
		return this.env === 'local';
	}

	register(instance: AgdirFeatureEnvironment): Environment {
		if (!(instance instanceof AgdirFeatureEnvironment)) {
			throw new Error('You can register only classes extended from AgdirFeatureEnvironment.');
		}
		if (!instance.NAME) {
			throw new Error('AgdirFeatureEnvironment constructor NAME is typed optional for simplier use, but actually its required');
		}
		this.features.set(instance.NAME, instance);
		return this;
	}

	get<T extends AgdirFeatureEnvironment>(
		type: { NAME: AgdirFeatureEnvironmentName; new (...params: any[]): T },
		{ throwOnError }: { throwOnError?: boolean } = { throwOnError: true },
	): T {
		const name = type.NAME;
		if (!this.features.has(name) && throwOnError) {
			if (!name) {
				console.error(type);
			}
			throw new Error(`No environment for a feature called ${name} was found`);
		}
		return this.features.get(name) as T;
	}

	has<T extends AgdirFeatureEnvironment>(type: { NAME: AgdirFeatureEnvironmentName; new (...params: any[]): T }): boolean {
		return this.features.has(type.NAME);
	}
}

import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirApiEnvironment extends AgdirFeatureEnvironment {
	readonly host: string;
	readonly hostv3: string;
	readonly webHost: string;
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirApiEnvironment';

	constructor(config: AgdirApiEnvironment) {
		super(AgdirApiEnvironment.NAME);
		this.host = config.host;
		this.hostv3 = config.hostv3;
		this.webHost = config.webHost;
	}
}

import { inject, Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@agdir/i18n/angular';

@Pipe({
	name: 'agdirItemName',
	pure: false,
	standalone: true,
})
export class ItemNamePipe implements PipeTransform {
	i18n = inject(I18nService);

	transform(input: Partial<{ name: string }>[] | Partial<{ name: string }>, translate = false): any {
		const items = Array.isArray(input) ? input : [input];
		return (items || [])
			.map((item) => item?.name || '')
			.map((name) => (translate ? this.i18n.translate(name) : name))
			.join(', ');
	}
}

import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirGoogleEnvironment extends AgdirFeatureEnvironment {
	readonly apiKey?: string;
	readonly reCaptchaV3SiteKey?: string;
	readonly googleAnalyticsTrackingCode: string;
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirGoogleEnvironment';

	constructor(config: AgdirGoogleEnvironment) {
		super(AgdirGoogleEnvironment.NAME);
		this.apiKey = config.apiKey;
		this.reCaptchaV3SiteKey = config.reCaptchaV3SiteKey;
		this.googleAnalyticsTrackingCode = config.googleAnalyticsTrackingCode;
	}
}

export const ALL_USERS = '*';

export enum AgdirFeature {
	journal = 'journal',
	journalIrrigation = 'journalIrrigation',
	journalWaste = 'journalWaste',
	journalSoilCultivation = 'journalSoilCultivation',
	cropProtection = 'cropProtection',
	accumulatedTemperature = 'accumulatedTemperature',
	locationDiseases = 'locationDiseases',
	sendFeedback = 'sendFeedback',
	pestsCatalogue = 'pestsCatalogue',
	geoRequestExplanation = 'geoRequestExplanation',
	ksl = 'ksl',
	hse = 'hse',
	storage = 'storage',
	harvest = 'harvest',
	fertilizerPlan = 'fertilizerPlan',
	journalObservations = 'journalObservations',
	shop = 'shop',
	chat = 'chat',
	machines = 'machines',
	fileManager = 'fileManager',
	precipitation = 'precipitation',
	journalTasksManagement = 'journalTasksManagement',
	journalFertilizer = 'journalFertilizer',
	digimapZoningLayers = 'digimapZoningLayers',
	locationNDVI = 'locationNDVI',
	locationSoilSamples = 'locationSoilSamples',
	locationAdvisoryService = 'locationAdvisoryService',
	locationFlyOverDrone = 'locationFlyOverDrone',
	digitalCourses = 'digitalCourses',
	webinars = 'webinars',
	SUBSCRIPTIONS = 'SUBSCRIPTIONS',
	TRIPLETEX = 'TRIPLETEX',
	INVENTORY = 'INVENTORY',
	VULTUS_ANALYSIS = 'VULTUS_ANALYSIS',
	VULTUS_ANALYSIS_DAILY_ESTIMATES = 'VULTUS_ANALYSIS_DAILY_ESTIMATES',
}

export interface AgdirFeatureSetup {
	pk: AgdirFeature;
	agdirGroup: Record<string, boolean>;
	subscriptionPlan: string[];
	country: string[];
	[ALL_USERS]: boolean;
}

export interface FeatureSubscriptionThing {
	id: string;
	name: string;
	prices: [{ id: string }];
}

export type AgdirFeatures = Record<AgdirFeature, boolean>;

export enum FeatureEvent {
	FeatureChanged = 'FeatureChanged',
}

export interface AgdirFeaturesDTO {
	features: AgdirFeatureSetup[];
	agdirGroups: string[];
}


export enum HandledSmsError {
	UsernameExistsException = 'UsernameExistsException',
	UserNotFoundException = 'UserNotFoundException',
	InvalidTokenCode = 'InvalidTokenCode',
	TokenRetriesExpired = 'TokenRetriesExpired',
	UnsupportedCountry = 'UnsupportedCountry',
	ForbiddenCountry = 'ForbiddenCountry',
	IncorrectPhoneNumber = 'IncorrectPhoneNumber',
}

export const HandledSmsHttpErrorStatus = 412;

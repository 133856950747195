import { NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { AgdirCardComponent } from './agdir-card.component';

@Component({
	standalone: true,
	selector: 'agdir-dashboard-widget',

	template: `
		<agdir-card [title]="title()" class="h-full relative">
			<ng-container afterTitle>
				<ng-content select="[afterTitle]"></ng-content>
			</ng-container>
			<ng-container menu>
				<ng-content select="[menu]"></ng-content>
			</ng-container>
			<div class="text-6xl sm:text-7xl font-semibold tracking-tight leading-none  text-center" *ngIf="bigText()">
				{{ bigText() }}
			</div>
			<div class="text-lg font-medium text-purple-500-800 dark:text-purple-500-200 text-center" *ngIf="smallText()">
				{{ smallText() }}
			</div>
			<div class="flex h-full w-full  flex-col items-center justify-center">
				<ng-content></ng-content>
			</div>
		</agdir-card>
	`,
	imports: [NgIf, AgdirCardComponent],
})
export class DashboardWidgetComponent {
	title = input('');
	bigText = input<string | number>('');
	smallText = input('');
}

export enum LocationEvents {
	LocationCreated = 'Location::Outlined',
	LocationUpdated = 'Location::Changed',
	cropTypeVariantSelected = 'Location::cropTypeVariantSelected',
	LocationGDDStartDateChanged = 'Location::LocationGDDStartDateChanged',
	LocationGDDDataUpdated = 'Location::LocationGDDDataUpdated',
	LocationDeleted = 'Location::Erased',
	accTempSettingsChanged = 'Location::accTempSettingsChanged',
	accTempSettingsReset = 'Location::accTempSettingsReset',
	cropTypeSelected = 'Location::cropTypeSelected',
	LinkedDevice = 'Location::LinkedDevice',
	unlinkedDevice = 'Location::unlinkedDevice',
	ForecastVendorSet = 'Location::ForecastVendorSet',
}

import { DeviceSensor } from '../devices';
import { TemperatureUnit, TemperatureUnitToSymbol } from '../units/temperatureUnit';
import { MeasureNameForMongo } from './measure-name';
import { MeasureUnitForMongo } from './measure-unit';

export const MeasureUnitForMongoToName = new Map<MeasureUnitForMongo, string>([
	[MeasureUnitForMongo.NOTSET, ''],
	[MeasureUnitForMongo.PERCENT, '%'],
	[MeasureUnitForMongo.PRESSURE_HPA, 'hPa'],
	[MeasureUnitForMongo.TEMPERATURE_CELCIUS, TemperatureUnitToSymbol(TemperatureUnit.CELSIUS)],
	[MeasureUnitForMongo.TEMPERATURE_FAHRENHEIT, TemperatureUnitToSymbol(TemperatureUnit.FAHRENHEIT)],
	[MeasureUnitForMongo.TEMPERATURE_KELVIN, TemperatureUnitToSymbol(TemperatureUnit.KELVIN)],
	[MeasureUnitForMongo.ELECTRICAL_VOLT, 'v'],
	[MeasureUnitForMongo.DBM, 'dBm'],
	[MeasureUnitForMongo.ELECTRICAL_CONDUCTIVITY_DESISIEMENSPERMETER, 'dS/m'],
	[MeasureUnitForMongo.VOLUME_MILLILITER, 'mm'],
	[MeasureUnitForMongo.TIME_MINUTE, 'min'],
	[MeasureUnitForMongo.PRESSURE_KPA, 'kPa'],
	[MeasureUnitForMongo['W/m2'], 'W/m2'],
	[MeasureUnitForMongo['J/m2'], 'J/m2'],
	[MeasureUnitForMongo['kJ/m2'], 'kJ/m2'],
	[MeasureUnitForMongo['MJ/m2'], 'MJ/m2'],
	[MeasureUnitForMongo.in, 'in'],
	[MeasureUnitForMongo.ft, 'ft'],
	[MeasureUnitForMongo.yd, 'yd'],
	[MeasureUnitForMongo['m (from hPa)'], 'm (from hPa)'],
	[MeasureUnitForMongo['in (from hPa)'], 'in (from hPa)'],
	[MeasureUnitForMongo.mbar, 'mbar'],
	[MeasureUnitForMongo.cbar, 'cbar'],
	[MeasureUnitForMongo.mmHg, 'mmHg'],
	[MeasureUnitForMongo.psi, 'psi'],
	[MeasureUnitForMongo['m/s'], 'm/s'],
	[MeasureUnitForMongo['km/h'], 'km/h'],
	[MeasureUnitForMongo.mph, 'mph'],
	[MeasureUnitForMongo['ft/min'], 'ft/min'],
	[MeasureUnitForMongo['ft/s'], 'ft/s'],
	[MeasureUnitForMongo['yd/min'], 'yd/min'],
	[MeasureUnitForMongo.kn, 'kn'],
	[MeasureUnitForMongo.deg, 'deg'],
]);

export const MeasureToPesslSensorMap = new Map<MeasureNameForMongo, DeviceSensor>([
	[MeasureNameForMongo.SOIL_TEMPERATURE, DeviceSensor.SOIL_TEMPERATURE],
	[MeasureNameForMongo.BATTERY_INTERNAL, DeviceSensor.BATTERY],
	[MeasureNameForMongo.BATTERY_EXTERNAL, DeviceSensor.SOLAR_PANEL],
	[MeasureNameForMongo.Precipitation, DeviceSensor.PRECIPITATION],
	[MeasureNameForMongo.SOLAR_RADIATION, DeviceSensor.SOLAR_RADIATION],
	[MeasureNameForMongo.LEAF_WETNESS, DeviceSensor.LEAF_WETNESS],
	[MeasureNameForMongo.AIR_TEMPERATURE, DeviceSensor.AIR_TEMPERATURE],
	[MeasureNameForMongo.AIR_HUMIDITY, DeviceSensor.RELATIVE_HUMIDITY],
	[MeasureNameForMongo.SunshineDuration, DeviceSensor.SUNSHINE_DURATION],
	[MeasureNameForMongo.WIND_DIRECTION, DeviceSensor.USONIC_WIND_DIR],
	[MeasureNameForMongo.WIND_SPEED, DeviceSensor.USONIC_WIND_SPEED],
	[MeasureNameForMongo.WIND_GUST, DeviceSensor.WIND_GUST],
	[MeasureNameForMongo.DeltaT, DeviceSensor.DELTAT],
	[MeasureNameForMongo.VPD, DeviceSensor.VPD],
	[MeasureNameForMongo.DewPoint, DeviceSensor.DEW_POINT],
	[MeasureNameForMongo.DAILY_ET0, DeviceSensor.DAILY_ET0],
]);

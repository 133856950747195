import { signal } from '@angular/core';
import { ClassifierAttribute } from './classifier-attribute';
import { ClassifierDto } from './classifierDto';
import { ClassifierList } from './classifierList';
import { ClassifierSearch } from './classifierSearch';
import { KnownClassifierCodes } from './knownClassifierCodes';
import { WellKnownClassifierAttribute } from './well-known-classifier-attribute';

export class Classifier {
	static BreadCrumbSeparator = ' / ';
	id: number;
	name: string;
	code: string | null = null;
	companyId: string | null = null;
	breadcrumb: string;
	parentId: number | null = null;
	attributes: ClassifierAttribute[] | null = null;
	children: Classifier[] | null = null;
	children$ = signal<Classifier[] | null>([]);
	numberOfCustomerClassifiers = 0;
	parent?: Classifier | null = null;

	private dto: Omit<ClassifierDto, 'id' | 'name'> | null = null;

	constructor(params: Pick<Classifier, 'id' | 'name'>) {
		this.id = params.id;
		this.name = params.name;
		this.breadcrumb = this.name;
		this.buildChildren();
	}

	static make(classifier: ClassifierDto) {
		return new Classifier({
			id: Number(classifier.id),
			name: classifier.name || '',
		}).hydrateWithDto(classifier);
	}

	toDto(): ClassifierDto {
		return {
			id: this.id > 0 ? this.id : undefined,
			name: this.name || '',
			code: this.code || '',
			parentId: this.parentId || 0,
			attributes: (this.attributes || []).map((attr) => attr.toDto()),
		};
	}

	walk(callback: (classifier: Classifier) => void) {
		callback(this);
		this.children?.forEach((child) => child.walk(callback));
	}

	makeBreadcrumb() {
		this.breadcrumb =
			this.parent && this.parent.parentId
				? [...this.parent.breadcrumb?.split(Classifier.BreadCrumbSeparator), this.name].join(Classifier.BreadCrumbSeparator)
				: this.name;
	}

	setParent(parent: Classifier): this {
		this.parentId = parent.id;
		this.parent = parent;
		return this;
	}

	hydrateWithDto(data: Omit<ClassifierDto, 'id' | 'name'>): this {
		this.dto = data;
		this.code = data.code || null;
		this.parentId = data.parentId || null;
		this.companyId = data.companyId || null;
		if (data.attributes) {
			this.attributes = data.attributes.map((attr) => ClassifierAttribute.fromDto(attr));
		} else {
			this.attributes = null;
		}
		if (data.children) {
			this.buildChildren(data.children);
			this.numberOfCustomerClassifiers = data.children.filter((c) => !!c.companyId).length;
		}
		this.makeBreadcrumb();
		return this;
	}

	clone(newId = false): Classifier {
		const clone = new Classifier({
			id: this.id,
			name: this.name,
		});
		if (this.dto) {
			clone.hydrateWithDto(this.dto);
		}
		if (newId) {
			clone.id = -1;
			clone.children = []; // deep clone pending
		}
		return clone;
	}

	toJSON(): ClassifierDto {
		return {
			id: this.id >= 0 ? this.id : undefined,
			name: this.name,
			code: this.code || undefined,
			parentId: this.parentId || undefined,
			attributes: this.attributes || undefined,
			children: this.children?.map((child) => child.toJSON()),
		};
	}

	addChild(classifier: Classifier) {
		if (!this.children) {
			this.children = [];
		}
		this.children.push(classifier.setParent(this));
		this.children$.set([...(this.children || [])]);
		return this;
	}

	deleteChild(classifier: Classifier) {
		if (!this.children) {
			return this;
		}
		const index = this.children.indexOf(classifier);
		if (index >= 0) {
			this.children.splice(index, 1);
			this.children$.set([...(this.children || [])]);
		}
		return this;
	}

	findChildByCode(code: string): Classifier | null {
		if (!code || !this.children) {
			return null;
		}
		return new ClassifierSearch(new ClassifierList(this.children)).findByCode(code);
	}

	findChildById(id: number): Classifier | null {
		if (!id || !this.children) {
			return null;
		}
		return new ClassifierSearch(new ClassifierList(this.children)).findChildById(id);
	}

	getAttribute(key: string) {
		return this.attributes?.find((a) => a.name === key);
	}

	getAttributeValue(key: string) {
		return this.getAttribute(key)?.value;
	}

	isLeaf() {
		return !this.children?.length;
	}

	matchesCode(cropType: string | undefined, { recursive }: { recursive: boolean } = { recursive: false }): boolean {
		return this.code === cropType || (recursive && this.children?.some((child) => child.matchesCode(cropType, { recursive }))) || false;
	}

	private buildChildren(children: ClassifierDto[] | null = null): this {
		this.children =
			children
				?.map((child) =>
					new Classifier({
						id: Number(child.id),
						name: child.name || '',
					})
						.setParent(this)
						.hydrateWithDto(child),
				)
				.sort((a, b) => (a.name > b.name ? 1 : -1)) || null;
		this.children$.set(this.children || null);
		return this;
	}

	setName(name: string) {
		this.name = name;
		this.makeBreadcrumb();
	}

	makeNewChild(name: string) {
		return new Classifier({ id: -1, name }).setParent(this);
	}

	getRoot(stopOn: string | KnownClassifierCodes | undefined = undefined): Classifier {
		let current: Classifier = this;
		while (current.parent && current.parent.code !== stopOn) {
			current = current.parent;
		}
		return current;
	}

	findAllParentAttributesByName(name: string | WellKnownClassifierAttribute): ClassifierAttribute[] {
		const found: ClassifierAttribute[] = [];
		let current: Classifier = this;
		while (current.parent) {
			if (current.attributes) {
				current.attributes
					.filter((attrs) => attrs.attributes?.length && attrs.name === name)
					.forEach(({ attributes }) => attributes?.length && found.push(...attributes));
			}
			current = current.parent;
		}
		return found;
	}
}

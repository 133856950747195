export enum DistanceUnit {
	MM = 1,
	IN = 2,
}

export const DistanceUnitToSymbol = (unit: DistanceUnit): string => {
	switch (unit) {
		case DistanceUnit.MM:
			return 'mm';
		case DistanceUnit.IN:
			return 'in';
		default:
			return '';
	}
};

const convertors = new Map<DistanceUnit, Map<DistanceUnit, (value: number) => number>>([
	[
		DistanceUnit.MM,
		new Map([
			[DistanceUnit.IN, (mm: number): number => mm / 25.4],
			[DistanceUnit.MM, (value) => value],
		]),
	],
	[
		DistanceUnit.IN,
		new Map([
			[DistanceUnit.MM, (inches: number): number => inches * 25.4],
			[DistanceUnit.IN, (value) => value],
		]),
	],
]);

export const convertDistanceUnit = (value: number | string | undefined | null, fromUnit: DistanceUnit, toUnit: DistanceUnit): number | null => {
	if (isNaN(Number(value)) || value === null || value === undefined) {
		return null;
	}
	return convertors.get(+fromUnit)?.get(+toUnit)?.(+value) ?? +value;
};

import { MeasureNameForMongo } from '@agdir/domain';
import { ObservationName } from '../observation-name';

export const mapMeasurementNameToObservationName = new Map<MeasureNameForMongo, ObservationName>([
	[MeasureNameForMongo.NOTSET, ObservationName.NotSet],
	[MeasureNameForMongo.FERTILIZER, ObservationName.Fertilizer],
	[MeasureNameForMongo.ELECTRICAL, ObservationName.Electrical],
	[MeasureNameForMongo.SIGNALSTRENGTHINDICATOR, ObservationName.SignalStrengthIndicator],
	[MeasureNameForMongo.CONDUCTIVITY, ObservationName.Conductivity],
	[MeasureNameForMongo.DIELECTRICITY, ObservationName.Dielectricity],
	[MeasureNameForMongo.SALINITY, ObservationName.Salinity],
	[MeasureNameForMongo.FIELDCAPACITY, ObservationName.FieldCapacity],
	[MeasureNameForMongo.WILTINGPOINT, ObservationName.WiltingPoint],
	[MeasureNameForMongo.WATERBALANCE, ObservationName.WaterBalance],
	[MeasureNameForMongo.BATTERYSTATUS, ObservationName.BatteryStatus],
	[MeasureNameForMongo.CONNECTIONMODE, ObservationName.ConnectionMode],
	[MeasureNameForMongo.RSSI, ObservationName.RSSI],
	[MeasureNameForMongo.DEVICE_STATUS, ObservationName.DeviceStatus],
	[MeasureNameForMongo.TRANSMISSIONMODE, ObservationName.TransmissionMode],
	[MeasureNameForMongo.SOIL_TEMPERATURE, ObservationName.SoilTemperature],
	[MeasureNameForMongo.SOIL_MOISTURE, ObservationName.SoilMoisture],
	[MeasureNameForMongo.AIR_TEMPERATURE, ObservationName.AirTemperature],
	[MeasureNameForMongo.AIR_HUMIDITY, ObservationName.AirHumidity],
	[MeasureNameForMongo.AIR_PRESSURE, ObservationName.AirPressure],
	[MeasureNameForMongo.TOUCH, ObservationName.Touch],
	[MeasureNameForMongo.MAC_ADDRESS, ObservationName.MACAddress],
	[MeasureNameForMongo.IP_ADDRESS, ObservationName.IPAddress],
	[MeasureNameForMongo.BATTERY_INTERNAL, ObservationName.BatteryInternal],
	[MeasureNameForMongo.BATTERY_EXTERNAL, ObservationName.BatteryExternal],
	[MeasureNameForMongo.SOLAR_RADIATION, ObservationName.SolarRadiation],
	[MeasureNameForMongo.SolarPanel, ObservationName.SolarPanel],
	[MeasureNameForMongo.Precipitation, ObservationName.Precipitation],
	[MeasureNameForMongo.LEAF_WETNESS, ObservationName.LeafWetness],
	[MeasureNameForMongo.HCSerialNumber, ObservationName.HCSerialNumber],
	[MeasureNameForMongo.DewPoint, ObservationName.DewPoint],
	[MeasureNameForMongo.VPD, ObservationName.VPD],
	[MeasureNameForMongo.DeltaT, ObservationName.DeltaT],
	[MeasureNameForMongo.WIND_SPEED, ObservationName.WindSpeed],
	[MeasureNameForMongo.WIND_DIRECTION, ObservationName.WindDirection],
	[MeasureNameForMongo.WIND_GUST, ObservationName.WindSpeedGusts],
	[MeasureNameForMongo.DAILY_ET0, ObservationName.DailyET0],
	[MeasureNameForMongo.WIND_ORIENTATION, ObservationName.WindOrientation],
	[MeasureNameForMongo.Midnight, ObservationName.Midnight],
	[MeasureNameForMongo.Sunrise, ObservationName.Sunrise],
	[MeasureNameForMongo.Sunset, ObservationName.Sunset],
	[MeasureNameForMongo.SunshineDuration, ObservationName.SunshineDuration],
]);

import { ObjectId } from 'mongodb';
import { LocationAddress } from '../locations/location-address';
import { UnderscoreIdModel } from '../underscore-id-model';
import { BusinessBranch } from './business-branch';
import { CompanyAccessLevel } from './company-access-level';
import { CompanyPreferences } from './company-preferences';

export type CompanyId = string;

export class CompanyPreferenceDefaults {
	static readonly tasksArchiveLength = 14;
}

export const businessBranches = [
	{ name: 'company.newCompany.Farm', value: 'farm' },
	{ name: 'company.newCompany.Golf', value: 'golf' },
	{ name: 'company.newCompany.Landscaping', value: 'work' },
	{ name: 'company.newCompany.Football', value: 'football' },
];

type StrictUnderscoreIdModel = UnderscoreIdModel & { _id: string | ObjectId };
export type CompanyWithId = Partial<Company> & Pick<Company, '_id'>;

export type CompanySubscription = {
	id?: string;
	planId?: string;
	status?: string;
	validTill?: Date | string;
};

export type Company = StrictUnderscoreIdModel & {
	organizationName?: string;
	organizationNr?: string;
	organizationVat?: string;
	businessBranch?: BusinessBranch;
	countryOfOrigin?: string | 'NO';
	soilScoutHubSiteId?: number;
	d21sProjectId?: string;
	address?: LocationAddress;
	preferences?: CompanyPreferences;
	subscription?: CompanySubscription;
};

export interface InviteToCompany {
	companyId?: string | null;
	businessNo?: string;
	name: string;
	ownerPhonePrefix: number;
	ownerPhone: number;
	permission?: CompanyAccessLevel;
}

import { Forecast, ForecastVendor, ForecastWindow } from '../deprecated-forecast/forecast';
import { Observation } from '../observation';
import { ObservationVendor } from '../observation-vendor';
import { ObservationWindow } from '../observation-window';
import { ObservationNameToWeatherProperty } from './observation-name-to-weather-property';

export const ObservationVendorMap = new Map<ObservationVendor, ForecastVendor>([
	[ObservationVendor.MetNo, ForecastVendor.METNO],
	[ObservationVendor.Pessl, ForecastVendor.PESSL],
	[ObservationVendor.OpenMeteo, ForecastVendor.OPENMETEO],
]);

export const ForecastVendorMap = new Map<ForecastVendor, ObservationVendor>([...ObservationVendorMap].map(([key, value]) => [value, key]));

export const mapObservationsToForecasts = (observations: Observation[]): Forecast[] => observations.map(mapObservationToForecast);
export const mapObservationToForecast = (observation: Observation): Forecast => {
	return {
		time: new Date(observation.time),
		value: observation.value,
		window: observation.window === ObservationWindow.day ? ForecastWindow.DAY : ForecastWindow.HOUR,
		vendor: ObservationVendorMap.get(observation.vendor),
		vendorId: observation.device?.externalIdentity,
		geoPoint: observation.geoPoint || undefined,
		locationId: observation.location?._id,
		name: ObservationNameToWeatherProperty.get(observation.name),
	};
};

import { Company, CompanyEvents } from '@agdir/domain';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, takeUntil } from 'rxjs';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { CompanyService } from '../companies';
import { WebsocketsService } from '../websockets/websockets.service';

@Injectable({ providedIn: 'root' })
export class DeviceSoilscoutConnectorService {
	private readonly httpClient = inject(HttpClient);
	private readonly websocketsService = inject(WebsocketsService);
	private readonly companyService = inject(CompanyService);

	waitForSoilScoutId(company: Company): Observable<boolean> {
		return this.companyService.getCompany(company._id).pipe(
			switchMap((c) => {
				return c.soilScoutHubSiteId
					? of(true)
					: this.createdSoilScoutHub(c).pipe(
							concatMap((result) => {
								if (result === true) {
									return of(true);
								} else {
									return this.websocketsService.messages$.pipe(
										filter((e) => e.data.action === CompanyEvents.CreatedSoilScoutHub),
										takeUntil(this.createdSoilScoutHub(c)),
										map(() => true),
									);
								}
							}),
					  );
			}),
		);
	}

	private createdSoilScoutHub(company: Company): Observable<any> {
		return this.httpClient.post<any>('/soilscout/create-hub-site', {
			_id: company._id,
			organizationName: company.organizationName,
		});
	}
}

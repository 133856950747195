import { Point } from '@turf/turf';
import { WeatherProperty } from './weather-property';
import { UnderscoreIdModel } from '@agdir/db/mongodb';

export enum ForecastVendor {
	METNO = 1,
	PESSL = 2,
	OPENMETEO = 3,
}

export enum ForecastWindow {
	DAY = 1,
	HOUR,
}

export interface Forecast extends Omit<ForecastDto, 'time' | 'forecastedAt'> {
	time: Date;
	forecastedAt?: Date;
}

export interface ForecastDto extends UnderscoreIdModel {
	time: string; // ISO
	forecastedAt?: string; // ISO
	value: number;
	window?: ForecastWindow;
	vendor: ForecastVendor;
	vendorId?: string; // TODO:find a better name its serial number (pessl) OR coordinates (metno)
	geoPoint: Point;
	locationId?: string;
	name: WeatherProperty;
	unitSymbol?: string;
}

import { MeasureForMongo } from './measure';
import { MeasureNameForMongo } from './measure-name';
import { MeasureUnitForMongo } from './measure-unit';
import { MeasureUnitForMongoToName } from './measurement-symbols';
import { NotAvailableMeasurement } from './NAMeasurements';

export class MeasurementTransformations {
	static getMeasure(measureName: MeasureNameForMongo, measurements: MeasureForMongo[] = []): MeasureForMongo | undefined {
		return measurements.find((m) => String(measureName) === String(m.name));
	}

	static getMeasureUnitAsString(measure?: Pick<MeasureForMongo, 'unit' | 'name'>): string {
		if (!measure) return '';
		return MeasureUnitForMongoToName.get(<MeasureUnitForMongo>measure.unit) ?? MeasurementTransformations.getDefaultUnitName(measure.name) ?? '';
	}

	static getMeasureValueString(
		measure?: Pick<MeasureForMongo, 'name' | 'value' | 'unit'>,
		withUnit = true,
		naValue = NotAvailableMeasurement,
	): string {
		const measureValueNumber = this.getMeasureValueNumber(measure);
		if (!measure || measureValueNumber === null) return naValue;
		const precision = this.getDefaultPrecision(measure.name);
		return measureValueNumber.toFixed(precision) + (withUnit ? this.getMeasureUnitAsString(measure) : '');
	}

	static getValueWithPrecision(value: number | null | undefined, name: MeasureNameForMongo) {
		if (value === null || value === undefined) {
			return null;
		}
		const multiplier = this.getDefaultMultiplier(name);
		const multiplied = value * multiplier;
		const precision = this.getDefaultPrecision(name);
		return multiplied.toFixed(precision);
	}

	static getMeasureValueNumber(measure?: Pick<MeasureForMongo, 'name' | 'value' | 'unit'>): number | null {
		if (!measure || measure.value === null) return null;
		const multiplier = this.getDefaultMultiplier(measure.name);
		return measure.value * multiplier;
	}

	static getDefaultPrecision(name: MeasureNameForMongo): number {
		switch (name) {
			case MeasureNameForMongo.LEAF_WETNESS:
				return 0;
			case MeasureNameForMongo.AIR_HUMIDITY:
			case MeasureNameForMongo.SOIL_TEMPERATURE:
			case MeasureNameForMongo.SOIL_MOISTURE:
			case MeasureNameForMongo.Precipitation:
			case MeasureNameForMongo.DAILY_ET0:
			case MeasureNameForMongo.WIND_SPEED:
				return 1;
			case MeasureNameForMongo.AIR_TEMPERATURE:
			case MeasureNameForMongo.BATTERY_INTERNAL:
			case MeasureNameForMongo.BATTERY_EXTERNAL:
			case MeasureNameForMongo.SALINITY:
			case MeasureNameForMongo.CONDUCTIVITY:
			case MeasureNameForMongo.WATERBALANCE:
			case MeasureNameForMongo.AIR_PRESSURE:
			case MeasureNameForMongo.DewPoint:
			case MeasureNameForMongo.VPD:
				return 2;
			default:
				return 0;
		}
	}

	static getDefaultUnitName(name: MeasureNameForMongo): string {
		switch (name) {
			case MeasureNameForMongo.AIR_HUMIDITY:
			case MeasureNameForMongo.SOIL_MOISTURE:
			case MeasureNameForMongo.WATERBALANCE:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.PERCENT) ?? '';
			case MeasureNameForMongo.Precipitation:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.VOLUME_MILLILITER) ?? '';
			case MeasureNameForMongo.AIR_TEMPERATURE:
			case MeasureNameForMongo.SOIL_TEMPERATURE:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.TEMPERATURE_CELCIUS) ?? '';
			case MeasureNameForMongo.CONDUCTIVITY:
			case MeasureNameForMongo.SALINITY:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.ELECTRICAL_CONDUCTIVITY_DESISIEMENSPERMETER) ?? '';
			// Disabled until we match DT (percent) and SS (dBms)
			// case MeasureNameForMongo.SIGNALSTRENGTHINDICATOR:
			// 	return MeasureUnitForMongoToName.get(MeasureUnitForMongo.DBM);
			case MeasureNameForMongo.AIR_PRESSURE:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.PRESSURE_HPA) ?? '';
			case MeasureNameForMongo.BATTERY_INTERNAL:
			case MeasureNameForMongo.BATTERY_EXTERNAL:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.ELECTRICAL_VOLT) ?? '';
			case MeasureNameForMongo.SOLAR_RADIATION:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo['W/m2']) ?? '';
			case MeasureNameForMongo.LEAF_WETNESS:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.TIME_MINUTE) ?? '';
			case MeasureNameForMongo.DewPoint:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.TEMPERATURE_CELCIUS) || '';
			case MeasureNameForMongo.VPD:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.PRESSURE_KPA) ?? '';
			case MeasureNameForMongo.DeltaT:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.TEMPERATURE_CELCIUS) ?? '';
			case MeasureNameForMongo.WIND_SPEED:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.mph) ?? '';
			case MeasureNameForMongo.WIND_DIRECTION:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.deg) ?? '';
			case MeasureNameForMongo.WIND_GUST:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo['m/s']) ?? '';
			case MeasureNameForMongo.DAILY_ET0:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.LENGTH_MILLIMETER) ?? '';
			case MeasureNameForMongo.WIND_ORIENTATION:
				return MeasureUnitForMongoToName.get(MeasureUnitForMongo.deg) ?? '';
		}
		return '';
	}

	static getDefaultUnit(name: MeasureNameForMongo): MeasureUnitForMongo {
		switch (name) {
			case MeasureNameForMongo.AIR_HUMIDITY:
			case MeasureNameForMongo.SOIL_MOISTURE:
			case MeasureNameForMongo.WATERBALANCE:
				return MeasureUnitForMongo.PERCENT;
			case MeasureNameForMongo.Precipitation:
				return MeasureUnitForMongo.VOLUME_MILLILITER;
			case MeasureNameForMongo.AIR_TEMPERATURE:
			case MeasureNameForMongo.SOIL_TEMPERATURE:
				return MeasureUnitForMongo.TEMPERATURE_CELCIUS;
			case MeasureNameForMongo.CONDUCTIVITY:
			case MeasureNameForMongo.SALINITY:
				return MeasureUnitForMongo.ELECTRICAL_CONDUCTIVITY_DESISIEMENSPERMETER;
			case MeasureNameForMongo.AIR_PRESSURE:
				return MeasureUnitForMongo.PRESSURE_HPA;
			case MeasureNameForMongo.BATTERY_INTERNAL:
			case MeasureNameForMongo.BATTERY_EXTERNAL:
				return MeasureUnitForMongo.ELECTRICAL_VOLT;
			case MeasureNameForMongo.SOLAR_RADIATION:
				return MeasureUnitForMongo['W/m2'];
			case MeasureNameForMongo.LEAF_WETNESS:
				return MeasureUnitForMongo.TIME_MINUTE;
			case MeasureNameForMongo.DewPoint:
				return MeasureUnitForMongo.TEMPERATURE_CELCIUS;
			case MeasureNameForMongo.VPD:
				return MeasureUnitForMongo.PRESSURE_KPA;
			case MeasureNameForMongo.DeltaT:
				return MeasureUnitForMongo.TEMPERATURE_CELCIUS;
			case MeasureNameForMongo.WIND_SPEED:
				return MeasureUnitForMongo.mph;
			case MeasureNameForMongo.WIND_DIRECTION:
				return MeasureUnitForMongo.deg;
			case MeasureNameForMongo.WIND_GUST:
				return MeasureUnitForMongo['m/s'];
			case MeasureNameForMongo.DAILY_ET0:
				return MeasureUnitForMongo.LENGTH_MILLIMETER;
			case MeasureNameForMongo.WIND_ORIENTATION:
				return MeasureUnitForMongo.deg;
		}
		return MeasureUnitForMongo.NOTSET;
	}

	static getDefaultMultiplier(name: MeasureNameForMongo): number {
		switch (name) {
			case MeasureNameForMongo.WATERBALANCE:
				return 100;
			default:
				return 1;
		}
	}
}

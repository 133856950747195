import { Classifier } from './classifier';
import { ClassifierAttribute } from './classifier-attribute';
import { ClassifierList } from './classifierList';
import { ClassifierAttributeType } from './classifier-attribute-type';

export class ClassifierSearchConfig {
	TREAT_NO_ATTRIBUTES_AS_TRUE = true;
	TREAT_NO_LOOKUP_ATTRIBUTES_AS_TRUE = true;
	TREAT_NOT_FOUND_MATCHING_ATTRIBUTES_AS_TRUE = false;

	make(config: Partial<ClassifierSearchConfig>) {
		if (config.hasOwnProperty('TREAT_NO_ATTRIBUTES_AS_TRUE')) {
			this.TREAT_NO_ATTRIBUTES_AS_TRUE = !!config.TREAT_NO_ATTRIBUTES_AS_TRUE;
		}
		if (config.hasOwnProperty('TREAT_NO_LOOKUP_ATTRIBUTES_AS_TRUE')) {
			this.TREAT_NO_LOOKUP_ATTRIBUTES_AS_TRUE = !!config.TREAT_NO_LOOKUP_ATTRIBUTES_AS_TRUE;
		}
		if (config.hasOwnProperty('TREAT_NOT_FOUND_MATCHING_ATTRIBUTES_AS_TRUE')) {
			this.TREAT_NOT_FOUND_MATCHING_ATTRIBUTES_AS_TRUE = !!config.TREAT_NOT_FOUND_MATCHING_ATTRIBUTES_AS_TRUE;
		}
	}
}

export class ClassifierSearch {
	private readonly filtered?: ClassifierList;

	constructor(
		private classifiers: ClassifierList,
		private config: ClassifierSearchConfig = new ClassifierSearchConfig(),
	) {
		this.filtered = classifiers.clone();
	}

	private hasAttributeValueAsCropId(classifier: Classifier, lookupAtributes: ClassifierList, attributeToMatch: string) {
		if (!classifier.attributes) {
			return this.config.TREAT_NO_ATTRIBUTES_AS_TRUE;
		}
		const foundAttributes = classifier.attributes.find((a) => a.name === attributeToMatch && a.type === ClassifierAttributeType.Attributes);
		if (!foundAttributes) {
			return this.config.TREAT_NO_LOOKUP_ATTRIBUTES_AS_TRUE;
		}
		const matchedLookupAttributes = lookupAtributes.toArray();
		const attrs: ClassifierAttribute[] = (foundAttributes.attributes || []) as ClassifierAttribute[];
		// Big O(n^2) but we are dealing with small arrays. kind of
		return (
			attrs.some((attr) => matchedLookupAttributes.some((c) => String(attr.value) === String(c.id))) ||
			this.config.TREAT_NOT_FOUND_MATCHING_ATTRIBUTES_AS_TRUE
		);
	}

	filterByAttribute(classifiers: ClassifierList = this.classifiers, attribute: string): this {
		for (const classifier of classifiers.toArray()) {
			if (!this.hasAttributeValueAsCropId(classifier, classifiers, attribute)) {
				this.filtered?.remove(classifier);
			}
			if (classifier.children) {
				this.filterByAttribute(new ClassifierList(classifier.children), attribute);
			}
		}
		return this;
	}

	getFiltered(): Classifier[] {
		return this.filtered?.toArray() || [];
	}

	findByCode(code: string): Classifier | null {
		if (!code) {
			return null;
		}
		return ClassifierSearch.findByRecursive(this.classifiers.toArray(), (c) => c.code === code);
	}

	findChildById(id: number): Classifier | null {
		if (!id) {
			return null;
		}
		return ClassifierSearch.findByRecursive(this.classifiers.toArray(), (c) => c.id === id);
	}

	static findByRecursive(classifiers: Classifier[], predicate: (c: Classifier) => boolean): Classifier | null {
		return classifiers.find(predicate) || classifiers.flatMap((c) => this.findByRecursive(c.children || [], predicate)).find(Boolean) || null;
	}
}

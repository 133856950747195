import { MeasureUnitForMongo } from '../measurements';

const roundToDecimals = (num: number, decimals = 2): number => Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);

export enum TemperatureUnit {
	CELSIUS = 1,
	FAHRENHEIT = 2,
	KELVIN = 3,
}

const convertors = new Map<TemperatureUnit, Map<TemperatureUnit, (value: number) => number>>([
	[
		TemperatureUnit.CELSIUS,
		new Map([
			[TemperatureUnit.FAHRENHEIT, (celsius: number): number => roundToDecimals((celsius * 9) / 5 + 32)],
			[TemperatureUnit.CELSIUS, (value) => value],
		]),
	],
	[
		TemperatureUnit.FAHRENHEIT,
		new Map([
			[TemperatureUnit.CELSIUS, (fahrenheit: number): number => roundToDecimals(((fahrenheit - 32) * 5) / 9)],
			[TemperatureUnit.FAHRENHEIT, (value) => value],
		]),
	],
]);

export const convertTemperatureUnit = (
	value: number | string | undefined | null,
	fromUnit: TemperatureUnit,
	toUnit: TemperatureUnit,
): number | null => {
	if (isNaN(Number(value)) || value === null || value === undefined) {
		return null;
	}
	return convertors.get(+fromUnit)?.get(+toUnit)?.(+value) ?? +value;
};

export const DefaultMeasurementTemperatureUnit = MeasureUnitForMongo.TEMPERATURE_CELCIUS;
export const DefaultTempearatureUnit = TemperatureUnit.CELSIUS;

export const TemperatureUnitToSymbol = (unit: TemperatureUnit): string => {
	switch (unit) {
		case TemperatureUnit.FAHRENHEIT:
			return '°F';
		case TemperatureUnit.KELVIN:
			return '°K';
		case TemperatureUnit.CELSIUS:
		default:
			return '°C';
	}
};

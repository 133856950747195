export enum CompanyAccessLevel {
	None = 0,
	Read = 1,
	Update = 2,
	Create = 4,
	Delete = 8,
	Share = 16,

	CRU = 7,
	CRUD = 15,
	OWNER = 31,
	ADVISOR = 65,
	RESERVED1 = 129,
	RESERVED2 = 255,
	RESERVED3 = 513,
	AGDIR = 1025,
}

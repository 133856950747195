import { AgdirSelectOption } from '@agdir/agdir-forms';
import { AgdirApiEnvironment } from '@agdir/core/angular';
import { I18nService } from '@agdir/i18n/angular';
import { CompanyAssetsV2Service, CompanyService } from '@agdir/services';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, firstValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { Classifier } from './classifier';
import { ClassifierDto } from './classifierDto';
import { KnownClassifierCodes } from './knownClassifierCodes';

const API_ENDPOINT = 'classifier';

export interface IClassifierServiceFetchParameters {
	translate?: boolean;
	forCompany?: boolean;
}

class DefaultClassifierServiceFetchParameters implements IClassifierServiceFetchParameters {
	translate = true;
	forCompany = true;
}

@Injectable({
	providedIn: 'root',
})
export class ClassifierService {
	private readonly companyAssetsService = inject(CompanyAssetsV2Service);
	private readonly companyService = inject(CompanyService);
	private readonly translate = inject(I18nService);
	private readonly cache = new Map<string, Observable<Classifier>>();
	private readonly promiseCache = new Map<string, Promise<Classifier>>();

	constructor() {
		this.companyService.whenChangingCompanies(() => {
			this.cache.clear();
			this.promiseCache.clear();
		});
	}

	getClassifierById(id: number, config: IClassifierServiceFetchParameters = new DefaultClassifierServiceFetchParameters()): Observable<Classifier> {
		const strid = String(id);
		if (!this.cache.has(strid)) {
			this.cache.set(strid, this.fetchRemoteClassifier(`${API_ENDPOINT}/${id}`, config).pipe(shareReplay(1)));
		}
		return this.cache.get(strid)!;
	}

	getClassifierByIdAsync(
		id: number,
		config: IClassifierServiceFetchParameters = new DefaultClassifierServiceFetchParameters(),
	): Promise<Classifier> {
		if (!this.promiseCache.has(String(id))) {
			const promise = new Promise<Classifier>((resolve, reject) => {
				this.fetchRemoteClassifier(`${API_ENDPOINT}/${id}`, config)
					.pipe(
						catchError((error) => {
							reject(error);
							return EMPTY;
						}),
					)
					.subscribe((classifier) => resolve(classifier));
			});
			this.promiseCache.set(String(id), promise);
		}
		return this.promiseCache.get(String(id))!;
	}

	getClassifierByCode(
		c: string | KnownClassifierCodes,
		config: IClassifierServiceFetchParameters = new DefaultClassifierServiceFetchParameters(),
	): Observable<Classifier> {
		const code = String(c);
		if (!this.cache.has(code)) {
			this.cache.set(code, this.fetchRemoteClassifier(`${API_ENDPOINT}/${code}/byCode`, config).pipe(shareReplay(1)));
		}
		return this.cache.get(code)!;
	}

	getClassifierByCodeAsync(
		c: string | KnownClassifierCodes,
		config: IClassifierServiceFetchParameters = new DefaultClassifierServiceFetchParameters(),
	): Promise<Classifier | null> | null {
		if (!c || c === 'null') {
			return null;
		}
		const code = String(c);
		if (this.promiseCache.has(code)) {
			return this.promiseCache.get(code)!;
		} else {
			try {
				const promise = new Promise<Classifier>((resolve, reject) => {
					this.fetchRemoteClassifier(`${API_ENDPOINT}/${code}/byCode`, config)
						.pipe(
							catchError((error) => {
								reject(error);
								return EMPTY;
							}),
						)
						.subscribe((classifier) => resolve(classifier));
				});
				this.promiseCache.set(code, promise);
			} catch (e) {
				return null;
			}
		}
		return this.promiseCache.get(code)!;
	}

	fetchRemoteClassifier(url: string, config: IClassifierServiceFetchParameters) {
		return this.companyAssetsService
			.get<ClassifierDto>(url, {
				params: {
					...(config.forCompany && { companyId: '{companyId}' }),
				},
			})
			.pipe(
				map((classifier) => (classifier ? Classifier.make(classifier) : null)),
				tap((classifier) => {
					if (classifier && config.translate) {
						classifier.walk((c) => c.setName(this.translate.translate(c.name)));
					}
				}),
			);
	}

	async addCustom(newClassifier: ClassifierDto): Promise<Classifier> {
		const { id } = await firstValueFrom(this.companyAssetsService.post<ClassifierDto>(`/{companyId}/${API_ENDPOINT}`, newClassifier));
		newClassifier.id = Number(id);
		newClassifier.companyId = this.companyService.currentCompanySignal()!._id!;
		return Classifier.make(newClassifier);
	}

	async insert(newClassifier: Classifier): Promise<void> {
		const { id } = await firstValueFrom(this.companyAssetsService.post<ClassifierDto>(API_ENDPOINT, newClassifier.toDto()));
		newClassifier.id = Number(id);
	}

	async update(newClassifier: Classifier): Promise<void> {
		await firstValueFrom(this.companyAssetsService.patch<ClassifierDto>(API_ENDPOINT, newClassifier.toDto()));
	}

	async delete(id: number): Promise<void> {
		await firstValueFrom(this.companyAssetsService.delete<void>(`${API_ENDPOINT}/${id}`));
	}

	getClassifiers(): Observable<Classifier[]> {
		return this.companyAssetsService.get<ClassifierDto[]>(API_ENDPOINT).pipe(map((classifiers) => classifiers.map((c) => Classifier.make(c))));
	}

	async getClassifiersAsync(): Promise<Classifier[]> {
		return firstValueFrom(this.getClassifiers());
	}

	search(namePart: string): Observable<Classifier[]> {
		return this.companyAssetsService
			.get<ClassifierDto[]>(`${API_ENDPOINT}/search?namePart=${namePart}`)
			.pipe(map((classifiers) => classifiers.map((c) => Classifier.make(c))));
	}

	async searchAsync(namePart: string): Promise<Classifier[]> {
		return firstValueFrom(this.search(namePart));
	}

	getClassifierByCodeAsAgdirSelectOptions(c: string | KnownClassifierCodes): Observable<AgdirSelectOption<Classifier>[]> {
		return this.getClassifierByCode(c).pipe(
			map(({ children }) =>
				(children || []).map((item) => ({
					value: item,
					label: item.name,
				})),
			),
			shareReplay(1),
		);
	}

	getClassifierByCodeAsAgdirSelectOptionsAsync(c: string | KnownClassifierCodes): Promise<AgdirSelectOption<Classifier>[]> {
		return firstValueFrom(this.getClassifierByCodeAsAgdirSelectOptions(c));
	}

	syncToProd(id: number) {
		return this.companyAssetsService.put<void>(`${API_ENDPOINT}/${id}/sync-to/${AgdirApiEnvironment.Prod}`, {});
	}
}

import { DeviceVendor } from '@agdir/domain';
import { ForecastVendor } from '../deprecated-forecast/forecast';
import { ObservationVendor } from '../observation-vendor';

export const ObservationVendorMap = new Map<DeviceVendor | ForecastVendor, ObservationVendor>([
	[ForecastVendor.METNO, ObservationVendor.MetNo],
	[ForecastVendor.PESSL, ObservationVendor.Pessl],
	[ForecastVendor.OPENMETEO, ObservationVendor.OpenMeteo],
	[DeviceVendor.NotSet, ObservationVendor.NotSet],
	[DeviceVendor.VardeV1, ObservationVendor.VardeV1],
	[DeviceVendor.SoilScout1, ObservationVendor.SoilScout1],
	[DeviceVendor.DisruptiveTech1, ObservationVendor.DisruptiveTech1],
	[DeviceVendor.Pessl, ObservationVendor.Pessl],
]);

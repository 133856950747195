import { AgdirHttpV2Client, IS_PUBLIC_API } from '@agdir/services';
import { HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	private readonly http = inject(AgdirHttpV2Client);

	getTranslation(lang: string) {
		return this.http.get<Translation>(`/i18n/${lang}`, { context: new HttpContext().set(IS_PUBLIC_API, true) });
	}
}

import { Component, effect, ElementRef, HostBinding, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AgdirIconService } from './agdir-icon.service';

export type IconSize = 'tiny' | 'small' | 'default' | 'large';

const sizeMap = new Map<IconSize, string>([
	['large', '1.4em'],
	['default', '1.2em'],
	['small', '1em'],
	['tiny', '0.8em'],
]);

@Component({
	standalone: true,
	selector: 'agdir-icon',
	template: `
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" [attr.viewBox]="DEFAULT_VIEWBOX"></svg>
	`,
	styles: [
		`
			:host {
				display: inline-block;
			}
		`,
	],
})
export class AgdirIconComponent {
	icon = input.required<string>();
	size = input<IconSize | null>('default');
	fill = input<string>('currentColor');
	stroke = input<string>('none');
	iconService = inject(AgdirIconService);
	sanitizer = inject(DomSanitizer);
	componentRef = inject(ElementRef);

	DEFAULT_VIEWBOX = '0 0 24 24';

	private BROKEN_IMAGE = 'broken_image';

	@HostBinding('style.width')
	@HostBinding('style.height')
	get selfSize() {
		const s = this.size();
		return s === null ? null : sizeMap.get(s);
	}

	constructor() {
		effect(async () => {
			const iconName = this.icon();
			const host: HTMLElement = this.componentRef.nativeElement;
			const svgElement: SVGElement = host.querySelector('svg') as SVGElement;
			if (iconName && svgElement) {
				svgElement.style.fill = this.fill();
				svgElement.style.stroke = this.stroke();
				const icon = await this.getIcon(iconName);
				if (icon) {
					// Does not work
					// this.makeLinkToSymbol(svgElement, iconName);
					this.makeSymbolAsIcon(svgElement, icon);
				} else {
					this.iconService.registerMissing(iconName);
					host.style.color = 'red';
					const brokenImage = await this.getIcon(this.BROKEN_IMAGE);
					if (brokenImage) {
						this.makeSymbolAsIcon(svgElement, brokenImage);
					}
				}
			}
		});
	}

	private async getIcon(iconName: string): Promise<SVGSymbolElement | null> {
		const cached = this.iconService.cacheRegistry.get(iconName);
		if (cached) {
			return cached;
		}
		const sprite = await this.iconService.getSvgSprite();
		try {
			const icon = sprite.querySelector(`#${iconName}`)?.cloneNode(true) as SVGSymbolElement;
			if (icon) {
				this.iconService.setToCache(iconName, icon);
			}
			return icon;
		} catch (e) {
			return null;
		}
	}

	makeLinkToSymbol(svgElement: HTMLElement, symbolId: string) {
		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.setAttribute('viewBox', this.DEFAULT_VIEWBOX);
		svg.setAttribute('width', '24');
		svg.setAttribute('height', '24');
		const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use');
		useElement.setAttribute('xlink:href', `#${symbolId}`);
		svg.appendChild(useElement);
		svgElement.innerHTML = '';
		svgElement.appendChild(svg);
	}

	private makeSymbolAsIcon(svgElement: SVGElement, iconSymbol: SVGSymbolElement) {
		const viewBox = iconSymbol.getAttribute('viewBox');
		if (viewBox && viewBox !== '0 0 0 0') {
			svgElement.setAttribute('viewBox', viewBox);
		}
		svgElement.innerHTML = '';
		this.putAllChildrenToSvg(iconSymbol, svgElement);
	}

	private putAllChildrenToSvg(iconSymbol: SVGSymbolElement, svg: SVGElement) {
		const children = Array.from(iconSymbol.children);
		children.forEach((child) => {
			svg.appendChild(child.cloneNode(true));
		});
	}
}

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { ChangeDetectionStrategy, Component, computed, HostBinding, input, ViewEncapsulation } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

export type ButtonSizeType = 'large' | 'default' | 'small' | 'fab' | 'medium-fab';
export type AgdirButtonColor =
	| 'primary'
	| 'secondary'
	| 'ghost'
	| 'outline'
	| 'red'
	| 'red-ghost'
	| 'red-outline'
	| 'green'
	| 'gray'
	| 'light-green'
	| 'yellow'
	| 'blue'
	| 'brown'
	| 'custom';

@Component({
	standalone: true,
	selector: 'agdir-button',
	template: `
		<button
			[disabled]="disabledState()"
			[type]="type()"
			[class.flex-row-reverse]="iconPosition() === 'after'"
			class="{{ (label() || '' | transloco: {} : language()) ? 'p-[6px_10px] md:p-[8px_12px]' : 'p-[6px] md:p-[8px]' }}"
		>
			@if (isProcessing()) {
				<agdir-spinner />
			}
			@if (!isProcessing() && icon(); as i) {
				<agdir-icon [icon]="i" [size]="null" [fill]="fill()" [stroke]="stroke()" />
			}
			@if (!isProcessing() && image(); as i) {
				<img [src]="i" />
			}
			@if (label()) {
				<span>{{ label() || '' | transloco: {} : language() }}</span>
			}
			<ng-content />
		</button>
	`,
	styleUrl: './button.component.scss',
	encapsulation: ViewEncapsulation.None, // Because we have theming on a body level.
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AgdirIconComponent, SpinnerComponent, TranslocoPipe],
})
export class ButtonComponent {
	isProcessing = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true || null });
	disabled = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true || null });
	disabledState = computed(() => this.disabled() || this.isProcessing());
	type = input<'button' | 'submit'>('button');
	icon = input<string | null>(null);
	image = input<string | null>(null);
	iconPosition = input<'before' | 'after'>('before');
	size = input<ButtonSizeType>('default');
	label = input<string, undefined | null | string | number>('', { transform: (value: string | null | undefined | number) => String(value) || '' });
	language = input<string>('');

	color = input<AgdirButtonColor>('secondary');
	fill = input<string>('currentColor');
	stroke = input<string>('none');

	@HostBinding('attr.color')
	get defaultColor() {
		return !this.color() ? 'secondary' : this.color();
	}

	@HostBinding('attr.size')
	get defaultSize() {
		return this.size();
	}
}

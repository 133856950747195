export enum WeatherProperty {
	// Number enums could be more cost-saving but we need to review usage of this because these also match metno.
	air_temperature = 'air_temperature',
	relative_humidity = 'relative_humidity',
	wind_from_direction = 'wind_from_direction',
	wind_speed = 'wind_speed',
	wind_speed_of_gust = 'wind_speed_of_gust',
	wind_speed_percentile_10 = 'wind_speed_percentile_10',
	wind_speed_percentile_90 = 'wind_speed_percentile_90',
	precipitation_amount = 'precipitation_amount',
	precipitation_amount_min = 'precipitation_amount_min',
	precipitation_amount_max = 'precipitation_amount_max',
	metno_code = 'metno_code',
	meteoblue_code = 'meteoblue_code',
	air_temperature_max = 'air_temperature_max',
	air_temperature_min = 'air_temperature_min',
	air_temperature_percentile_10 = 'air_temperature_percentile_10',
	air_temperature_percentile_90 = 'air_temperature_percentile_90',
	air_pressure_at_sea_level = 'air_pressure_at_sea_level',
	dew_point_temperature = 'dew_point_temperature',
	probability_of_precipitation = 'probability_of_precipitation',
	snow_probability = 'snow_probability',
	wind_speed_min = 'wind_speed_min',
	relative_humidity_max = 'relative_humidity_max',
	relative_humidity_min = 'relative_humidity_min',
	leaf_wetness = 'leaf_wetness',
	global_radiation_shf = 'global_radiation_shf',
	wind_orientation = 'wind_orientation',
	snow_amount = 'snow_amount',
	sunshine_time = 'sunshine_time',
	cloud_cover = 'cloud_cover',
	cloud_area_fraction = 'cloud_area_fraction',
	cloud_area_fraction_low = 'cloud_area_fraction_low',
	cloud_area_fraction_medium = 'cloud_area_fraction_medium',
	cloud_area_fraction_high = 'cloud_area_fraction_high',
	fog_area_fraction = 'fog_area_fraction',
	air_surface_pressure = 'air_surface_pressure',
	sea_level_pressure = 'sea_level_pressure',
	isdaylight = 'isdaylight',
	soiltemperature_0to10cm = 'soiltemperature_0to10cm',
	wetbulbtemperature = 'wetbulbtemperature',
	global_radiation_ghi = 'global_radiation_ghi',
	snow_cover = 'snow_cover',
	daily_et0 = 'daily_et0',
}

import { WeatherProperty } from '../deprecated-forecast/weather-property';
import { ObservationName } from '../observation-name';

export const ObservationNameToWeatherProperty = new Map<ObservationName, WeatherProperty>([
	[ObservationName.AirTemperature, WeatherProperty.air_temperature],
	[ObservationName.AirTemperatureMin, WeatherProperty.air_temperature_min],
	[ObservationName.AirTemperatureMax, WeatherProperty.air_temperature_max],
	[ObservationName.AirTemperaturePercentile90, WeatherProperty.air_temperature_percentile_90],
	[ObservationName.AirTemperaturePercentile10, WeatherProperty.air_temperature_percentile_10],
	[ObservationName.AirPressure, WeatherProperty.air_pressure_at_sea_level],
	[ObservationName.SoilTemperature, WeatherProperty.soiltemperature_0to10cm],
	[ObservationName.PrecipitationProbability, WeatherProperty.probability_of_precipitation],
	[ObservationName.Precipitation, WeatherProperty.precipitation_amount],
	[ObservationName.PrecipitationMax, WeatherProperty.precipitation_amount_max],
	[ObservationName.PrecipitationMin, WeatherProperty.precipitation_amount_min],
	[ObservationName.DailyET0, WeatherProperty.daily_et0],
	[ObservationName.AirHumidity, WeatherProperty.relative_humidity],
	[ObservationName.LeafWetness, WeatherProperty.leaf_wetness],
	[ObservationName.WindSpeed, WeatherProperty.wind_speed],
	[ObservationName.WindOrientation, WeatherProperty.wind_orientation],
	[ObservationName.WindSpeedGusts, WeatherProperty.wind_speed_of_gust],
	[ObservationName.SolarRadiation, WeatherProperty.global_radiation_ghi],
	[ObservationName.IconMetno, WeatherProperty.metno_code],
	[ObservationName.IconMeteoBlue, WeatherProperty.meteoblue_code],
	[ObservationName.DewPoint, WeatherProperty.dew_point_temperature],
	[ObservationName.CloudAreaFraction, WeatherProperty.cloud_area_fraction],
	[ObservationName.CloudAreaFractionLow, WeatherProperty.cloud_area_fraction_low],
	[ObservationName.CloudAreaFractionMedium, WeatherProperty.cloud_area_fraction_medium],
	[ObservationName.CloudAreaFractionHigh, WeatherProperty.cloud_area_fraction_high],
	[ObservationName.CloudCover, WeatherProperty.cloud_cover],
	[ObservationName.FogAreaFraction, WeatherProperty.fog_area_fraction],
]);

export const WeatherPropertyToObservationName = new Map<WeatherProperty, ObservationName>([
	[WeatherProperty.air_temperature, ObservationName.AirTemperature],
	[WeatherProperty.air_temperature_min, ObservationName.AirTemperatureMin],
	[WeatherProperty.air_temperature_max, ObservationName.AirTemperatureMax],
	[WeatherProperty.air_temperature_percentile_90, ObservationName.AirTemperaturePercentile90],
	[WeatherProperty.air_temperature_percentile_10, ObservationName.AirTemperaturePercentile10],
	[WeatherProperty.air_pressure_at_sea_level, ObservationName.AirPressure],
	[WeatherProperty.soiltemperature_0to10cm, ObservationName.SoilTemperature],
	[WeatherProperty.probability_of_precipitation, ObservationName.PrecipitationProbability],
	[WeatherProperty.precipitation_amount, ObservationName.Precipitation],
	[WeatherProperty.precipitation_amount_min, ObservationName.PrecipitationMin],
	[WeatherProperty.precipitation_amount_max, ObservationName.PrecipitationMax],
	[WeatherProperty.daily_et0, ObservationName.DailyET0],
	[WeatherProperty.relative_humidity, ObservationName.AirHumidity],
	[WeatherProperty.leaf_wetness, ObservationName.LeafWetness],
	[WeatherProperty.wind_speed, ObservationName.WindSpeed],
	[WeatherProperty.wind_orientation, ObservationName.WindOrientation],
	[WeatherProperty.wind_speed_of_gust, ObservationName.WindSpeedGusts],
	[WeatherProperty.wind_speed_percentile_90, ObservationName.WindSpeedPercentile90],
	[WeatherProperty.wind_speed_percentile_10, ObservationName.WindSpeedPercentile10],
	[WeatherProperty.global_radiation_ghi, ObservationName.SolarRadiation],
	[WeatherProperty.metno_code, ObservationName.IconMetno],
	[WeatherProperty.meteoblue_code, ObservationName.IconMeteoBlue],
	[WeatherProperty.dew_point_temperature, ObservationName.DewPoint],
	[WeatherProperty.cloud_area_fraction, ObservationName.CloudAreaFraction],
	[WeatherProperty.cloud_area_fraction_low, ObservationName.CloudAreaFractionLow],
	[WeatherProperty.cloud_area_fraction_medium, ObservationName.CloudAreaFractionMedium],
	[WeatherProperty.cloud_area_fraction_high, ObservationName.CloudAreaFractionHigh],
	[WeatherProperty.cloud_cover, ObservationName.CloudCover],
	[WeatherProperty.fog_area_fraction, ObservationName.FogAreaFraction],
	[WeatherProperty.wind_from_direction, ObservationName.WindDirection],
]);

import { AuthCandidate, AuthCandidateSession, AuthTokens, HandledSmsHttpErrorStatus } from '@agdir/bifrost';
import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirAwsEnvironment } from '@agdir/environment/domain';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
	CognitoAccessToken,
	CognitoIdToken,
	CognitoRefreshToken,
	CognitoUser,
	CognitoUserPool,
	CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { combineLatestWith, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from './customer';
import { AgdirCompany, CompanyAccessLevel } from '@agdir/domain';
import { CompanyService } from '../companies';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { AssetsStorageService } from '../assets-storage.service';

export const CUSTOMER_PATHS = {
	ALL_FARMS: '/',
	WELCOME: '/auth/welcome',
	INVITATIONS: '/auth/received-invitations',
	AUTH_ONBOARDING: '/auth/sign-in/customer',
	AUTH_SIGNIN: '/auth/sign-in',
	AUTH_SIGNUP: '/auth/sign-up',
	AUTH_SIGNOUT: '/auth/sign-out',
	NEW_CUSTOMER_PROFILE: '/auth/new-customer-profile',
};

const deleteAllCookies = () => {
	const cookies = document.cookie.split(';');
	for (const cookie of cookies) {
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
};

@Injectable({ providedIn: 'root' })
export class AuthService {
	private readonly config: AgdirAwsEnvironment = inject(ENVIRONMENT).get(AgdirAwsEnvironment);
	private refreshUser$ = new ReplaySubject<Customer>(1);

	private restService = inject(HttpClient);
	private companyService = inject(CompanyService);

	async getCurrentCustomer(): Promise<Customer> {
		return Customer.fromCurrentSession({
			UserPoolId: this.config.userPoolId,
			ClientId: this.config.userPoolWebClientId,
		});
	}

	getCurrentCustomerSession(): Observable<Customer> {
		return fromPromise(this.getCurrentCustomer());
	}

	startNewCustomerSession(payload: AuthCandidate): Promise<AuthCandidateSession> {
		return firstValueFrom(this.restService.post<AuthCandidateSession>(this.getAuthEndpointUrl(payload.withSignup), payload));
	}

	async answerCustomChallenge(candidate: AuthCandidateSession, withSignup = true): Promise<AuthTokens> {
		try {
			const tokens = await firstValueFrom(this.restService.patch<AuthTokens>(this.getAuthEndpointUrl(withSignup), candidate));
			this.initializeCustomerWithTokens(candidate.phone, tokens);
			return tokens;
		} catch (err: any) {
			throw err.status === HandledSmsHttpErrorStatus ? err.error : err;
		}
	}

	async refreshToken(): Promise<string> {
		const currentCustomer = await this.getCurrentCustomer();
		return new Promise((resolve, reject) => {
			const refreshToken = currentCustomer?.cognitoUser?.getSignInUserSession()?.getRefreshToken();
			if (refreshToken) {
				currentCustomer.cognitoUser?.refreshSession(refreshToken, (error, newSession) => {
					if (error) {
						if (error.code === 'NotAuthorizedException') {
							debugger;
							window.location.href = CUSTOMER_PATHS.AUTH_SIGNOUT;
						}
						reject(error);
					} else {
						currentCustomer.setSession(newSession);
						this.refreshUser$.next(currentCustomer);
						resolve(newSession?.getIdToken().getJwtToken());
					}
				});
			} else {
				reject();
			}
		});
	}

	async signOut(): Promise<boolean> {
		const currentCustomer = await this.getCurrentCustomer();
		return new Promise((resolve) => {
			setTimeout(() => resolve(true), 10000); // if you are signed out it will not fire the callback and there is no exception or anything.
			AssetsStorageService.clear();
			window.sessionStorage.clear();
			deleteAllCookies();
			currentCustomer?.cognitoUser?.signOut(() => resolve(true));
		});
	}

	getMyCurrentPermission(): Observable<CompanyAccessLevel> {
		return this.getCurrentCustomerSession().pipe(
			combineLatestWith(this.companyService.getCurrentCompany()),
			map(([client, company]) => client.companyPermissions[company._id] as CompanyAccessLevel),
		);
	}

	getMyCurrentPermissionAsync(): Promise<CompanyAccessLevel> {
		return firstValueFrom(this.getMyCurrentPermission());
	}

	private initPool(): CognitoUserPool {
		return Customer.initPool({
			UserPoolId: this.config.userPoolId,
			ClientId: this.config.userPoolWebClientId,
		});
	}

	private initializeCustomerWithTokens(phone: string, tokens: AuthTokens) {
		const session = new CognitoUserSession({
			AccessToken: new CognitoAccessToken({ AccessToken: tokens.access }),
			RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refresh }),
			IdToken: new CognitoIdToken({ IdToken: tokens.id }),
		});
		const pendingCognitoUser = new CognitoUser({
			Username: phone,
			Pool: this.initPool(),
		});
		pendingCognitoUser.setSignInUserSession(session);
		this.refreshUser$.next(new Customer(pendingCognitoUser).setSession(session));
	}

	private getAuthEndpointUrl(withSignup: boolean): string {
		return `/bifrost/sign-${withSignup ? 'up' : 'in'}`;
	}

	amITheCurrentCompanyOwner(): Observable<boolean> {
		return this.amIAtLeastRole(CompanyAccessLevel.OWNER);
	}

	amIAgdirPowerUser(): Observable<boolean> {
		return this.getCurrentCustomerSession().pipe(
			map((customer) => {
				const token = customer.cognitoUser?.getSignInUserSession()?.getIdToken().decodePayload();
				return !!(token && token['cognito:groups']?.includes(AgdirCompany.cognitoAdminGroupName));
			}),
		);
	}

	amIAgdirPowerUserAsync(): Promise<boolean> {
		return firstValueFrom(this.amIAgdirPowerUser());
	}

	amIAtLeastRole(role: CompanyAccessLevel): Observable<boolean> {
		return this.getCurrentCustomerSession().pipe(
			combineLatestWith(this.companyService.getCurrentCompany()),
			map(([client, company]) => client.companyPermissions[company._id] >= role),
		);
	}
}

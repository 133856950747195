import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JsonCircularDepFixInterceptor implements HttpInterceptor {
	static decirculize(obj: any): any {
		const cache: any[] = [];
		return JSON.parse(
			JSON.stringify(obj, (key, value) =>
				typeof value === 'object' && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			),
		);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(
			req.clone({
				body: this.decirculize(req.body),
			}),
		);
	}

	decirculize(obj: any): any {
		return JsonCircularDepFixInterceptor.decirculize(obj);
	}
}

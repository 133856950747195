import { toSignal } from '@angular/core/rxjs-interop';
import { inject, Injectable, Signal } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
	private breakpointObserver = inject(BreakpointObserver);

	isMobile(): Signal<boolean> {
		return this.observableMatch('(max-width: 767px)');
	}

	isTablet(exactly = false): Signal<boolean> {
		return this.observableMatch(exactly ? '(min-width: 768px) and (max-width: 1279px)' : '(min-width: 768px)');
	}

	isSmallDesktop(exactly = false): Signal<boolean> {
		return this.observableMatch(exactly ? '(min-width: 1280px) and (max-width: 1919px)' : '(min-width: 1280px)');
	}

	isLargeDesktop(): Signal<boolean> {
		return this.observableMatch('(min-width: 1920px)');
	}

	private observableMatch(query: string) {
		return toSignal(this.breakpointObserver.observe(query).pipe(map((state) => state.matches)), { requireSync: true });
	}
}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AgdirIconService {
	private svgSpriteUrl = 'assets/agdir-icons-sprite.svg';
	iconCache: Element = this.initIconCache();
	MISSING_ICONS = new Set<string>();
	cacheRegistry = new Map<string, SVGSymbolElement>();

	constructor() {
		(window as any).reportMissingAgdirIcons = () => {
			console.error('Missing icons:', Array.from(this.MISSING_ICONS));
		};
	}

	private svgSprite$: Promise<SVGElement> = firstValueFrom(
		inject(HttpClient)
			.get(this.svgSpriteUrl, { responseType: 'text' })
			.pipe(
				map((svgText: string) => {
					const parser = new DOMParser();
					return parser.parseFromString(svgText, 'image/svg+xml').documentElement as unknown as SVGElement;
				}),
			),
	);

	getSvgSprite(): Promise<SVGElement> {
		return this.svgSprite$;
	}

	setToCache(name: string, svgElement: SVGSymbolElement) {
		this.iconCache.appendChild(svgElement);
		this.cacheRegistry.set(name, svgElement);
	}

	registerMissing(iconName: string) {
		this.MISSING_ICONS.add(iconName);
		console.error(`Icon "${iconName}" not found in sprite.\nYou can find all missing icons with window.reportMissingAgdirIcons()`);
	}

	private initIconCache() {
		const iconCache = document.querySelector('#agdir-icon-cache');
		if (!iconCache) {
			this.iconCache = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
			this.iconCache.setAttribute('id', 'agdir-icon-cache');
			this.iconCache.setAttribute('style', 'display: none;');
			this.iconCache.setAttribute('aria-hidden', 'true');
			this.iconCache.setAttribute('role', 'presentation');
			document.body.prepend(this.iconCache);
			return this.iconCache;
		}
		return iconCache;
	}
}

import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirRemoteMFAEnvironment extends AgdirFeatureEnvironment {
	readonly authSite?: string;
	readonly tasksManagement?: string;
	readonly journal?: string;
	readonly fileManager?: string;
	readonly catalogue?: string;
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirRemoteMFAEnvironment';

	constructor(config: AgdirRemoteMFAEnvironment) {
		super(AgdirRemoteMFAEnvironment.NAME);
		this.authSite = config.authSite;
		this.fileManager = config.fileManager;
		this.tasksManagement = config.tasksManagement;
		this.journal = config.journal;
		this.catalogue = config.catalogue;
	}
}

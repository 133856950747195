import { CustomerPreferences, Profile, ProfileWithCustomerId } from '@agdir/domain';
import { inject, Injectable, signal } from '@angular/core';
import { AgdirHttpV2Client } from '../api';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	private readonly profileCache = signal<ProfileWithCustomerId | null>(null);
	private readonly profile$ = toObservable(this.profileCache);
	profile = this.profileCache.asReadonly();
	private readonly apiUrl = 'heimdall/customer';
	private readonly http = inject(AgdirHttpV2Client);
	private readonly authService = inject(AuthService);

	getProfileSignal = this.profileCache.asReadonly();

	getProfile(): Observable<ProfileWithCustomerId | null> {
		return this.profile$;
	}

	constructor() {
		this.get();
	}

	getPreferences() {
		return this.profileCache()?.preferences ?? {};
	}

	getPreferenceSignal<T extends CustomerPreferences[keyof CustomerPreferences]>(preference: keyof CustomerPreferences, fallback?: T): T {
		const profile = this.profileCache();
		if (!profile) {
			return fallback as T;
		}
		return (profile.preferences?.[preference] as T) ?? (fallback as T);
	}

	async getPreferenceAsync<T extends CustomerPreferences[keyof CustomerPreferences]>(
		preference: keyof CustomerPreferences,
		fallback?: T,
	): Promise<T> {
		const profile = this.profileCache();
		return (profile?.preferences?.[preference] as T) ?? (fallback as T);
	}

	async get(): Promise<ProfileWithCustomerId> {
		if (!this.profileCache()) {
			const remoteProfile = await this.http.getAsync<ProfileWithCustomerId>(this.apiUrl);
			const profile = { onboardingCompleted: false } as ProfileWithCustomerId;
			if (remoteProfile) {
				Object.assign(profile, remoteProfile);
			}
			if (!remoteProfile) {
				const customer = await this.authService.getCurrentCustomer();
				if (customer) {
					profile.customerId = customer._id;
					profile.email = customer.email;
					profile.name = customer.name;
					profile.phoneNumber = customer.phone;
				}
			}
			this.profileCache.set(profile);
		}
		return this.profileCache()!;
	}

	async update(profile: Partial<Profile>): Promise<ProfileWithCustomerId> {
		const updatedProfile = await this.http.patchAsync<ProfileWithCustomerId>(this.apiUrl, profile);
		this.profileCache.set(updatedProfile);
		return updatedProfile;
	}

	async updateProperty(property: keyof ProfileWithCustomerId, value: any): Promise<Profile> {
		const profile = this.profileCache();
		if (profile) {
			return this.update({
				...profile,
				[property]: value,
			});
		} else {
			throw new Error('Profile not found');
		}
	}

	async updateCustomerAttribute(attribute: string, value: string) {
		const profile = this.profileCache();
		return this.updateProperty('attributes', { ...profile?.attributes, [attribute]: value });
	}

	async updatePreference(property: keyof CustomerPreferences, value: any) {
		const profile = this.profileCache();
		return this.updateProperty('preferences', { ...profile?.preferences, [property]: value });
	}
}

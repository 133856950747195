import { ButtonComponent } from '@agdir/ui/button';
import {
	AfterViewInit,
	Component,
	computed,
	ElementRef,
	HostBinding,
	inject,
	input,
	model,
	OnDestroy,
	output,
	Renderer2,
	signal,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { ResponsiveService } from './responsive';

const COLUMN_WIDTH = 64;
const MOBILE_GAP = 8;
const TABLET_GAP = 24;

@Component({
	standalone: true,
	selector: 'agdir-card',
	template: `
		<ng-content select="[pre]"></ng-content>
		<div class="card-header gap-[8px] lg:gap-[12px] grid grid-cols-[min_content,min_content] grid-rows-[min_content,min_content]">
			@if (!noTitle()) {
				<div class="flex-row-between w-full header-wrapper flex-1 title-wrapper">
					<div class="flex-row-start gap-1 md:gap-2 flex-1 title-itself">
						<ng-content select="[beforeTitle]"></ng-content>

						<h3 [class]="css().title">
							@if (title()) {
								@if (title() && translate()) {
									{{ title() | transloco: translationParams() : language() }}
								} @else if (title()) {
									{{ title() }}
								}
							}
						</h3>
						<ng-content select="[titlePostfix]"></ng-content>
					</div>
					<div class="flex-row-end gap-1 md:gap-2 title-actions">
						@if (collapsible()) {
							<agdir-button
								color="ghost"
								[icon]="collapsed() ? 'unfold_more' : 'unfold_less'"
								[label]="
									col3Wide() || col2Wide() || col4Wide() ? '' : collapsed() ? 'general.expandSidebar' : 'general.collapseSidebar'
								"
								(click)="toggleExpand()"
							/>
						}
						<ng-content select="[menu]"></ng-content>
						@if (showFullscreen()) {
							<agdir-button
								size="small"
								color="ghost"
								(click)="toggleFullscreen()"
								icon="{{ fullscreen() ? 'fullscreen_exit' : 'fullscreen' }}"
							/>
						}
					</div>
				</div>
				<ng-content select="[afterTitle]"></ng-content>
				@if (subtitle() && translate()) {
					<h4 [class]="css().subtitle">{{ subtitle() | transloco: translationParams() }}</h4>
				} @else if (subtitle()) {
					<h4 [class]="css().subtitle">{{ subtitle() }}</h4>
				}
			}
			<ng-content select="[subTitle]"></ng-content>
		</div>

		@if (!collapsed()) {
			<ng-content></ng-content>
		}
	`,
	imports: [ButtonComponent, TranslocoPipe],
	styles: [
		`
			:host {
				/*overflow: hidden;*/
				@apply block bg-white rounded-[10px] relative;
				@apply shadow-[0px_6px_22px_0px_rgba(0,0,0,0.08)];
				@apply py-[16px] px-[12px] md:p-2 lg:p-3 xl:p-4;
				/*@apply text-[12px] lg:text-[16px];*/
				@apply text-[16px];
				/* When we started work on mobile, all of them had an exception of 16px */
				@apply gap-[8px] md:gap-[12px] lg:gap-[16px] xl:gap-[24px];
				/*@apply xl:p-4 gap-[24px];*/
				display: flex;
				flex-direction: column;
				justify-content: start;
				align-items: start;
				justify-items: start;
				align-content: start;
			}
		`,
		`
			:host > * {
				width: 100%;
			}
		`,
		`
			.title-itself,
			.title-actions,
			.title-wrapper {
				&:empty {
					display: none;
				}
			}
		`,
		`
			:host.cursor-pointer:hover {
				cursor: pointer;
				box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.18);
			}
		`,
		`
			:host.isProcessing:before {
				display: block;
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 3px;
				background: linear-gradient(
					to right,
					rgba(0, 0, 0, 0.1),
					rgba(0, 0, 0, 0.2),
					rgba(0, 0, 0, 0.1),
					rgba(0, 0, 0, 0.1),
					rgba(0, 0, 0, 0.2),
					rgba(0, 0, 0, 0.1)
				);
				animation: moveGradient 2s infinite ease-in-out;
			}

			@keyframes moveGradient {
				0% {
					transform: translateX(100%);
				}
				100% {
					transform: translateX(-100%);
				}
			}
		`,
		`
			:host.fullscreen {
				@apply fixed inset-0 z-[1000] bg-white top-0 left-0 right-0 bottom-0;
			}
		`,
		`
			h3 {
				@apply font-bold;
			}
		`,
		`
			.header-wrapper {
				flex: 0 0 auto !important;
			}
		`,
		`
			h4 {
				flex: 0 0 auto !important;
				@apply break-words;
			}
		`,
		`
			.card-header:has(+ *:not(:empty)) {
				@apply mb-[12px] lg:mb-[16px] xl:mb-[24px];
			}
		`,
		`
			.card-header:has(*:has(*:empty)) {
				display: none !important;
			}
		`,
	],
})
export class AgdirCardComponent implements AfterViewInit, OnDestroy {
	showFullscreen = input<boolean, boolean | 'true' | 'false'>(false, { transform: (v) => v === 'true' || v === true });
	translate = input<boolean, boolean | 'true' | 'false'>(true, { transform: (v) => v === 'true' || v === true });
	title = input<string>('');
	language = input<string | undefined>(undefined);
	subtitle = input<string>('');
	collapsible = input<boolean, boolean | 'true' | 'false'>(false, { transform: (v) => v === 'true' || v === true });
	isProcessing = input<boolean>(false);
	noTitle = input<boolean>(false);
	fullscreen = signal<boolean>(false);
	collapsed = model<boolean>(false);
	fullscreenToggled = output<boolean>();
	translationParams = input<Record<string, unknown>>({});
	protected isMobile = inject(ResponsiveService).isMobile();
	protected isTablet = inject(ResponsiveService).isTablet(true);
	protected isSmallDesktop = inject(ResponsiveService).isSmallDesktop(true);
	protected isLargeDesktop = inject(ResponsiveService).isLargeDesktop();

	protected css = computed<{ title: string; subtitle: string }>(() => {
		if (this.isLargeDesktop()) {
			if (this.col2Wide() || this.col3Wide()) {
				return {
					title: 'figma-header3',
					subtitle: 'figma-body-text-regular',
				};
			} else {
				return {
					title: 'figma-header2',
					subtitle: 'figma-body-text-regular',
				};
			}
		} else if (this.isSmallDesktop()) {
			if (this.col2Wide() || this.col3Wide()) {
				return {
					title: 'figma-mobile-header',
					subtitle: 'figma-body-text-regular',
				};
			} else {
				return {
					title: 'figma-header3',
					subtitle: 'figma-body-text-regular ',
				};
			}
		} else if (this.isTablet()) {
			if (this.col2Wide() || this.col3Wide()) {
				return {
					title: 'figma-mobile-header',
					subtitle: 'figma-body-text-small-regular ',
				};
			} else {
				return {
					title: 'figma-header3',
					subtitle: 'figma-body-text-regular ',
				};
			}
		} else {
			return {
				title: 'figma-mobile-header',
				subtitle: 'figma-body-text-regular',
				// subtitle: 'figma-body-text-small-regular ',
			};
		}
	});
	protected width = signal<number>(0);
	col2Wide = computed(() => (this.isMobile() ? this.width() <= 357 : this.width() === COLUMN_WIDTH * 2 + this.gap()));
	col4Wide = computed(() => (this.isMobile() ? !this.col2Wide() : this.width() === COLUMN_WIDTH * 4 + this.gap() * 3));
	col3Wide = computed(() => this.width() === COLUMN_WIDTH * 3 + this.gap() * 2);
	col6Wide = computed(() => this.width() === COLUMN_WIDTH * 6 + this.gap() * 5);
	private resizeObserver: ResizeObserver = new ResizeObserver((entries) => {
		for (const entry of entries) {
			if (entry.contentRect) {
				this.width.set(this.el.nativeElement.offsetWidth);
				// this.width.set(entry.contentRect.width);
			}
		}
	});

	@HostBinding('class.isProcessing') get doesAngularHaveSignalClass() {
		return this.isProcessing();
	}

	@HostBinding('class.px-2') get doesAngularHaveSignalClass2() {
		return this.isSmallDesktop() && (this.col3Wide() || this.col2Wide());
	}

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
	) {}

	toggleExpand() {
		this.collapsed.update((value) => !value);
	}

	collapse() {
		this.collapsed.set(true);
	}

	expand() {
		this.collapsed.set(false);
	}

	toggleFullscreen() {
		const hostElement = this.el.nativeElement;
		if (this.fullscreen()) {
			this.fullscreen.set(false);
			this.fullscreenToggled.emit(false);
			this.renderer.removeClass(hostElement, 'fullscreen');
		} else {
			this.fullscreen.set(true);
			this.fullscreenToggled.emit(true);
			this.renderer.addClass(hostElement, 'fullscreen');
		}
	}

	gap() {
		return this.isMobile() ? MOBILE_GAP : TABLET_GAP;
	}

	ngAfterViewInit() {
		this.resizeObserver.observe(this.el.nativeElement);
		this.width.set(this.el.nativeElement.offsetWidth);
	}

	ngOnDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	}
}

// when adding new languages, remember to update provideAgdirTranslationProviders

export const SUPPORTED_LANGUAGES = new Map<SUPPORTED_LANGUAGE, string>([
	['no', 'Norsk'],
	['en', 'English'],
	['es', 'Espaňol'],
	['fi', 'Suomeksi'],
	['pl', 'Polski'],
	['ua', 'Українська'],
	['it', 'Italiano'],
	['sv', 'Svenska'],
	['da', 'Dansk'],
	['lv', 'Latviešu'],
]);
export const SUPPORTED_LANGUAGES_SHORT = new Map<SUPPORTED_LANGUAGE, string>([
	['no', 'NO'],
	['en', 'EN'],
	['es', 'ES'],
	['fi', 'SU'],
	['pl', 'PO'],
	['ua', 'УКР'],
	['it', 'IT'],
	['sv', 'SV'],
	['da', 'DA'],
	['lv', 'LV'],
]);

export const DEFAULT_USER_LANGUAGE = 'en';
export const LOCAL_STORAGE_USER_LANGUAGE_KEY = 'userLanguage';
export const getUserLanguage = () => (window.localStorage.getItem(LOCAL_STORAGE_USER_LANGUAGE_KEY) || DEFAULT_USER_LANGUAGE) as SUPPORTED_LANGUAGE;
export const setUserLanguage = (lang: SUPPORTED_LANGUAGE) => window.localStorage.setItem(LOCAL_STORAGE_USER_LANGUAGE_KEY, lang);
export type SUPPORTED_LANGUAGE = 'no' | 'lv' | 'uk' | 'ua' | 'gb' | 'it' | 'es' | 'fi' | 'dk' | 'sv' | 'us' | 'lt' | 'pl' | string;

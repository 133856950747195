import { Company, CompanyCropPreference } from '@agdir/domain';
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { firstValueFrom, merge, Observable, of, ReplaySubject, switchMap, throwError } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { AgdirHttpV2Client } from '../api';
import { AssetsStorageKey, AssetsStorageService } from '../assets-storage.service';

const API = `/heimdall/company`;

@Injectable({ providedIn: 'root' })
export class CompanyService {
	private currentCompany$ = new ReplaySubject<Company>(1);
	private readonly callbacksWhenChangingCompanies: Array<(company?: Company) => void> = [];
	private company$: Observable<Company> = this.currentCompany$.pipe(
		tap((company) => this.callbacksWhenChangingCompanies.forEach((cb) => cb(company))),
		shareReplay(1),
	);

	readonly currentCompanySignal = toSignal(this.currentCompany$);

	constructor(private httpClient: AgdirHttpV2Client) {}

	updateCurrentCompany(patch: Partial<Company>): Observable<Company> {
		return this.getCurrentCompany().pipe(
			first(),
			switchMap((company) => {
				delete patch._id;
				const patchedCompany = { ...company, ...patch };
				return this.httpClient.patch<void>(`${API}/${company._id}`, patchedCompany).pipe(
					tap(() => {
						Object.assign(company, { ...patchedCompany });
						this.currentCompany$.next(company);
						this.updateCache(company);
					}),
					map(() => company),
				);
			}),
		);
	}

	async updateCurrentCompanyAsync(patch: Partial<Company>): Promise<Company> {
		return firstValueFrom(this.updateCurrentCompany(patch));
	}

	async updateCompany(patch: Company): Promise<Company> {
		await this.httpClient.patchAsync<void>(`${API}/${patch._id}`, patch);
		this.currentCompany$.next(patch);
		this.updateCache(patch);
		return patch;
	}

	whenChangingCompanies(cb: () => void) {
		this.callbacksWhenChangingCompanies.push(cb);
	}

	getCompany(companyId: string): Observable<Company> {
		return !new RegExp(/^[a-zA-Z0-9]{24}$/).test(String(companyId))
			? throwError(() => new Error(`${companyId} is not a valid companyId`))
			: this.httpClient.get<Company | null>(`${API}/${companyId}`).pipe(
					switchMap((company) => {
						return company ? of(company) : throwError(() => new Error(`${companyId} is not a valid company`));
					}),
				);
	}

	createCompany(company: Omit<Company, '_id'>): Promise<Company> {
		return firstValueFrom(this.httpClient.post<Company>(API, company));
	}

	createCompanyForMe(company: Omit<Company, '_id'>): Promise<Company> {
		return firstValueFrom(this.httpClient.post<Company>(API + '/my', company));
	}

	setCurrentCompany(company: Company): void {
		this.currentCompany$.next(company);
	}

	getCurrentCompany(): Observable<Company> {
		return this.company$;
	}

	getCurrentCompanyAsync(): Promise<Company> {
		return firstValueFrom(this.getCurrentCompany());
	}

	isCurrentCompanyInNorway(): Observable<boolean> {
		return this.getCurrentCompany().pipe(map((company) => company.countryOfOrigin === 'NO'));
	}

	getAllAllAllCompanies(): Observable<Company[]> {
		const localCache = AssetsStorageService.get<Company[]>(AssetsStorageKey.companies);
		const remoteCall = this.httpClient
			.get<Company[]>(API)
			.pipe(tap((companies) => AssetsStorageService.set(AssetsStorageKey.companies, companies)));
		return localCache ? merge(of(localCache), remoteCall) : remoteCall;
	}

	updateCache(company: Company) {
		const localCache = AssetsStorageService.get<Company[]>(AssetsStorageKey.companies);
		if (localCache) {
			const updatedCache = localCache.map((c) => (c._id === company._id ? company : c));
			AssetsStorageService.set(AssetsStorageKey.companies, updatedCache);
		}
	}

	getAllAllAllCompaniesAsync(): Promise<Company[]> {
		return firstValueFrom(this.getAllAllAllCompanies());
	}
	async findByNumber(company: Company): Promise<Company> {
		const foundCompany = await firstValueFrom(
			this.httpClient.get<Company>(`${API}/find-by-organization-number/${company.countryOfOrigin}/${company.organizationNr}`),
		);
		this.currentCompany$.next(foundCompany);
		return foundCompany;
	}

	async updateCropColor(companyCropPreference: CompanyCropPreference) {
		const company = await firstValueFrom(this.getCurrentCompany());
		const cropPreferences = company.preferences?.cropPreferences || [];
		const hasCrop = cropPreferences.some((c) => c.cropId === companyCropPreference.cropId);

		const updatedCompany = {
			...company,
			preferences: {
				...company.preferences,
				cropPreferences: hasCrop
					? cropPreferences.map((c) => (c.cropId === companyCropPreference.cropId ? companyCropPreference : c))
					: [...cropPreferences, companyCropPreference],
			},
		};
		return this.updateCurrentCompanyAsync(updatedCompany);
	}
}

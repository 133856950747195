import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface RestOptions {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	context?: HttpContext;
	observe?: 'body';
	params?:
		| HttpParams
		| {
				[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		  };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({ providedIn: 'root' })
/**
 * Do we even need it now? With interceptors this service is dumb as ...
 */
export class RestService {
	constructor(private httpClient: HttpClient) {}

	get<T>(route: string, params?: RestOptions): Observable<T> {
		return this.httpClient.get<T>(route, params);
	}

	put<T>(route: string, data: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.put<T>(route, data, params);
	}

	delete<T>(route: string, data?: any, params?: RestOptions): Observable<T> {
		return this.httpClient.delete<T>(route, {
			...params,
			body: data,
		});
	}

	post<T>(route: string, data: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.post<T>(route, data, params);
	}

	patch<T>(route: string, data: any | null, params?: RestOptions): Observable<T> {
		return this.httpClient.patch<T>(route, data, params);
	}
}

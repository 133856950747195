import { MeasurementChartGroup } from '@agdir/domain';
import { AgdirHttpV2Client } from '@agdir/services';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChartGroupService {
	static API_ENDPOINT = 'chart-groups';

	protected readonly http = inject(AgdirHttpV2Client);

	getAllGroupsWithCache() {
		return this.http.getWithCache<MeasurementChartGroup[]>(`${ChartGroupService.API_ENDPOINT}`);
	}
}

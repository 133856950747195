import { AgdirFeatureEnvironment, AgdirFeatureEnvironmentName } from './agdir-feature-environment';

export class AgdirTranslationsEnvironment extends AgdirFeatureEnvironment {
	readonly availableLanguages: string[];
	static readonly NAME: AgdirFeatureEnvironmentName = 'AgdirTranslationsEnvironment';

	constructor({ availableLanguages }: AgdirTranslationsEnvironment) {
		super(AgdirTranslationsEnvironment.NAME);
		this.availableLanguages = availableLanguages;
	}
}

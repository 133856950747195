import { registerLocaleData } from '@angular/common';
import { EnvironmentProviders, LOCALE_ID, Provider } from '@angular/core';
import { provideTransloco } from '@ngneat/transloco';
import { da_DK, en_GB, es_ES, fi_FI, it_IT, lv_LV, nb_NO, NZ_DATE_CONFIG, pl_PL, provideNzI18n, sv_SE, uk_UA } from 'ng-zorro-antd/i18n';
import { DEFAULT_USER_LANGUAGE, getUserLanguage, setUserLanguage, SUPPORTED_LANGUAGE, TranslocoHttpLoader } from '../index';

import ngDa from '@angular/common/locales/da';
import ngLv from '@angular/common/locales/lv';
import ngEn from '@angular/common/locales/en';
import ngEs from '@angular/common/locales/es';
import ngFi from '@angular/common/locales/fi';
import ngIt from '@angular/common/locales/it';
import ngNb from '@angular/common/locales/nb';
import ngPl from '@angular/common/locales/pl';
import ngSv from '@angular/common/locales/sv';
import nbUk from '@angular/common/locales/uk';

import { setDefaultOptions } from 'date-fns';
import {
	da as dateFnsDa,
	enGB as dateFnsEnGB,
	es as dateFnsEs,
	fi as dateFnsFi,
	it as dateFnsIt,
	lv as dateFnsLv,
	nb as dateFnsNb,
	pl as dateFnsPl,
	sv as dateFnsSv,
	uk as dateFnsUk,
} from 'date-fns/locale';
import { SUPPORTED_LANGUAGES } from '../SUPPORTED_LANGUAGES';

registerLocaleData(ngNb);
registerLocaleData(ngEn);
registerLocaleData(ngEs);
registerLocaleData(ngFi);
registerLocaleData(ngPl);
registerLocaleData(nbUk);
registerLocaleData(ngIt);
registerLocaleData(ngSv);

interface AgdirLocaleConfiguration {
	code: SUPPORTED_LANGUAGE;
	localeId: string;
	nzI18n: any;
	cb?: () => void;
}

const agdirLocaleRegistry = new Map<SUPPORTED_LANGUAGE, AgdirLocaleConfiguration>([]);
agdirLocaleRegistry.set('no', {
	code: 'no',
	localeId: 'nb-NO',
	nzI18n: nb_NO,
	cb: () => {
		setDefaultOptions({ locale: dateFnsDa });
	},
});

agdirLocaleRegistry.set('en', {
	code: 'en',
	localeId: 'en-GB',
	nzI18n: en_GB,
	cb: () => {
		setDefaultOptions({ locale: dateFnsEnGB });
	},
});
agdirLocaleRegistry.set('es', {
	code: 'es',
	localeId: 'es-ES',
	nzI18n: es_ES,
	cb: () => {
		setDefaultOptions({ locale: dateFnsEs });
	},
});

agdirLocaleRegistry.set('fi', {
	code: 'fi',
	localeId: 'fi-FI',
	nzI18n: fi_FI,
	cb: () => {
		setDefaultOptions({ locale: dateFnsFi });
	},
});

agdirLocaleRegistry.set('pl', {
	code: 'pl',
	localeId: 'pl-PL',
	nzI18n: pl_PL,
	cb: () => {
		setDefaultOptions({ locale: dateFnsIt });
	},
});

agdirLocaleRegistry.set('ua', {
	code: 'ua',
	localeId: 'uk-UA',
	nzI18n: uk_UA,
	cb: () => {
		setDefaultOptions({ locale: dateFnsNb });
	},
});

agdirLocaleRegistry.set('it', {
	code: 'it',
	localeId: 'it-IT',
	nzI18n: it_IT,
	cb: () => {
		setDefaultOptions({ locale: dateFnsPl });
	},
});

agdirLocaleRegistry.set('sv', {
	code: 'sv',
	localeId: 'sv-SE',
	nzI18n: sv_SE,
	cb: () => {
		setDefaultOptions({ locale: dateFnsSv });
	},
});

agdirLocaleRegistry.set('da', {
	code: 'da',
	localeId: 'da-DK',
	nzI18n: da_DK,
	cb: () => {
		registerLocaleData(ngDa);
		setDefaultOptions({ locale: dateFnsUk });
	},
});
agdirLocaleRegistry.set('lv', {
	code: 'lv',
	localeId: 'lv-LV',
	nzI18n: lv_LV,
	cb: () => {
		registerLocaleData(ngLv);
		setDefaultOptions({ locale: dateFnsLv });
	},
});

export const provideAgdirTranslationProviders = (): Array<Provider | EnvironmentProviders> => {
	const userLanguage = getUserLanguage();
	const browserLanguage = window.navigator.language.substr(0, 2);
	const cfg = agdirLocaleRegistry.get(userLanguage) || agdirLocaleRegistry.get(browserLanguage) || agdirLocaleRegistry.get(DEFAULT_USER_LANGUAGE)!;
	if (userLanguage !== cfg.code) {
		setUserLanguage(cfg.code);
	}
	cfg.cb?.();
	return [
		{ provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
		{ provide: LOCALE_ID, useValue: cfg.localeId },
		provideNzI18n(cfg.nzI18n),
		provideTransloco({
			config: {
				availableLangs: [...SUPPORTED_LANGUAGES.keys()],
				fallbackLang: 'en',
				defaultLang: userLanguage,
				prodMode: true,
				reRenderOnLangChange: false,
				missingHandler: {
					useFallbackTranslation: true,
				},
			},
			loader: TranslocoHttpLoader,
		}),
	];
};

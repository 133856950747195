import { AgdirIconComponent } from '@agdir/agdir-icons';
import { Component, input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-spinner',
	template: `
		<agdir-icon icon="rotate_right" [size]="null" />
	`,
	styles: [
		`
			:host {
				font-size: inherit;
				@apply flex items-center justify-center w-2 h-2;
			}

			agdir-icon {
				font-size: inherit;
				animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				width: inherit;
				height: inherit;
			}
			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		`,
	],
	imports: [AgdirIconComponent],
})
export class SpinnerComponent {
	color = input<string>('#405c70');
}

export enum WeightUnit {
	KILOGRAM = 1,
	TON = 2,
	POUND = 3,
}

export const WeightUnitToSymbol = (unit: WeightUnit | null = null): string => {
	switch (unit) {
		case WeightUnit.KILOGRAM:
			return 'kg';
		case WeightUnit.TON:
			return 't';
		case WeightUnit.POUND:
			return 'lb';
		default:
			return '';
	}
};

const convertors = new Map<WeightUnit, Map<WeightUnit, (value: number) => number>>([
	[
		WeightUnit.KILOGRAM,
		new Map([
			[WeightUnit.TON, (kg: number): number => kg / 1000],
			[WeightUnit.POUND, (kg: number): number => kg * 2.20462],
			[WeightUnit.KILOGRAM, (value) => value],
		]),
	],
	[
		WeightUnit.TON,
		new Map([
			[WeightUnit.KILOGRAM, (tons: number): number => tons * 1000],
			[WeightUnit.POUND, (tons: number): number => tons * 2204.62],
			[WeightUnit.TON, (value) => value],
		]),
	],
	[
		WeightUnit.POUND,
		new Map([
			[WeightUnit.KILOGRAM, (lbs: number): number => lbs / 2.20462],
			[WeightUnit.TON, (lbs: number): number => lbs / 2204.62],
			[WeightUnit.POUND, (value) => value],
		]),
	],
]);

export const convertWeightUnit = (value: number | string | undefined | null, fromUnit: WeightUnit, toUnit: WeightUnit): number | null => {
	if (isNaN(Number(value)) || value === null || value === undefined) {
		return null;
	}
	return convertors.get(+fromUnit)?.get(+toUnit)?.(+value) ?? +value;
};

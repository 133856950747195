import { Directive, OnDestroy } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';

@Directive()
export abstract class BaseDirective implements OnDestroy {
	destroyed$ = new Subject<any>();

	ngOnDestroy(): void {
		this.destroyed$.next(EMPTY);
		this.destroyed$.unsubscribe();
	}
}

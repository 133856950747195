import { convertTemperatureUnit, DefaultMeasurementTemperatureUnit, DefaultTempearatureUnit, TemperatureUnit } from '../units/temperatureUnit';
import { MeasureForMongo } from './measure';
import { MeasureNameForMongo } from './measure-name';
import { MeasureUnitForMongo } from './measure-unit';

export const TemperatureUnitToMeasurementUnit = new Map<TemperatureUnit, MeasureUnitForMongo>([
	[TemperatureUnit.CELSIUS, MeasureUnitForMongo.TEMPERATURE_CELCIUS],
	[TemperatureUnit.FAHRENHEIT, MeasureUnitForMongo.TEMPERATURE_FAHRENHEIT],
	[TemperatureUnit.KELVIN, MeasureUnitForMongo.TEMPERATURE_KELVIN],
]);

export const MeasurementUnitToTemperatureUnit = new Map<MeasureUnitForMongo, TemperatureUnit>([
	[MeasureUnitForMongo.TEMPERATURE_CELCIUS, TemperatureUnit.CELSIUS],
	[MeasureUnitForMongo.TEMPERATURE_FAHRENHEIT, TemperatureUnit.FAHRENHEIT],
	[MeasureUnitForMongo.TEMPERATURE_KELVIN, TemperatureUnit.KELVIN],
]);

export const isTemperatureMeasurement = (unit: MeasureUnitForMongo): boolean => {
	return [MeasureUnitForMongo.TEMPERATURE_CELCIUS, MeasureUnitForMongo.TEMPERATURE_FAHRENHEIT, MeasureUnitForMongo.TEMPERATURE_KELVIN].includes(
		unit,
	);
};

export const isTemperatureMeasurementName = (name: MeasureNameForMongo): boolean => {
	return [MeasureNameForMongo.AIR_TEMPERATURE, MeasureNameForMongo.SOIL_TEMPERATURE].includes(name);
};

export const convertMeasurementTemperatureToOtherUnit = (measurement: MeasureForMongo, toUnit: TemperatureUnit): MeasureForMongo => {
	const measurementUnit = Number(measurement.unit);
	if (!isTemperatureMeasurement(measurementUnit)) {
		return measurement;
	}
	const fromTemperatureUnit: TemperatureUnit =
		MeasurementUnitToTemperatureUnit.get(measurementUnit ?? DefaultMeasurementTemperatureUnit) ?? DefaultTempearatureUnit;
	const toMeasurementUnit: MeasureUnitForMongo =
		TemperatureUnitToMeasurementUnit.get(+toUnit || DefaultMeasurementTemperatureUnit) ?? measurementUnit ?? MeasureUnitForMongo.NOTSET;
	if (measurementUnit && toMeasurementUnit !== measurementUnit) {
		measurement.value = convertTemperatureUnit(measurement.value, fromTemperatureUnit, toUnit) ?? measurement.value;
		measurement.minValue = convertTemperatureUnit(measurement.minValue, fromTemperatureUnit, toUnit) ?? measurement.minValue;
		measurement.maxValue = convertTemperatureUnit(measurement.maxValue, fromTemperatureUnit, toUnit) ?? measurement.maxValue;
		measurement.unit = toMeasurementUnit;
	}
	return measurement;
};

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService, CUSTOMER_PATHS } from '../auth.service';

export const IsAnonymousGuard: CanActivateFn = async () => {
	const auth = inject(AuthService);
	const router = inject(Router);
	try {
		const s = await auth.getCurrentCustomer();
		return !s?.cognitoUser ? true : router.navigateByUrl(CUSTOMER_PATHS.WELCOME);
	} catch {
		return true;
	}
};
